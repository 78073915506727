import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsGETApi, AdsPOSTApi, AdsPUTApi } from "../../../Constant/api";
import { slugs } from "../../../Constant/slugs";
import ImageUploadAuto from "./ImageUpload1";

export default function ColorVisionAdmin(props) {
  const location = useLocation()
  const history = useHistory()
  const [ID, setID] = React.useState(245394)
  const [Title, setTitle] = React.useState("")

  const [reload, setReload] = React.useState(false)
  const [ImageArray, setImageArray] = React.useState([])
  const [ImageArrayReload, setImageArrayReload] = React.useState("")
  const [QuestionsBlock, setQuestionsBlock] = React.useState(true);
  const [QuestionsData, setQuestionsData] = React.useState([]);
  
  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")
  const EditInfo = () => {
    setReload(true)
    const data = {
      "meta": {
        images_list: JSON.stringify(QuestionsData)
      }
    }
    AdsPUTApi(data, "color_vision", ID)
      .then((res) => {
        setReload(false)
        setAlertShow(true)
      }).catch(err => {
        setReload(false)
        setAlertShow(true)
        setAlertcomp(err.response.data.message)
      })
  }
  useEffect(() => {
    fetchSliders()
  }, [])
  const fetchSliders = () => {
    setImageArrayReload(false)
    AdsGETApi({ _fields: "meta,title,id" }, `color_vision/${ID}`)
      .then((res) => {
        if (res.data.meta.images_list.length !== 0)
          setQuestionsData(JSON.parse(res.data.meta.images_list))
        setTitle(res.data.title.rendered)
        setImageArrayReload(true)
      })
      .catch(() => {

      })
  }
  const onChangeFunction = (key, value, index) => {
    var changeValue = {
      ...Object.values(QuestionsData)[index],
      [key]: value
    }
    var AllChange = {
      ...QuestionsData,
      [Object.keys(QuestionsData)[index]]: changeValue
    }
    setQuestionsData(AllChange)

  }

  return (
    <div>
      <div>
        <div className="row" style={{ padding: "30px 30px" }}>
          <div className="col-md-8 button">
            <h2>{Title}</h2>
          </div>
          <div className="col-md-4" style={{ textAlign: 'right' }}>
            <a href="/">Dashboard</a> / <a href={slugs.all_books}>All Image</a>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2 col-1"></div>
          <div className="col-sm-8 col-10 form-view mb-3">
            <h4><center>List</center></h4>
            <table>
              <tbody>
                <tr>
                
                </tr>
              </tbody>
            </table>
            <div className="row center m-2">
              <div className=" col-3" style={{ padding: "0 10px" }}>
                <div className="sign-in-button-4"
                  onClick={() => {
                    EditInfo()
                  }}
                >
                  Update Images
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Notes</center></h4>
          <div style={{ display: "inline", justifyContent: "flex-end" }}>
            <div style={{ cursor: "pointer", display: "inline-block", margin: "5px", color: QuestionsBlock ? "#000000" : "#444444", fontWeight: QuestionsBlock ? "bold" : "normal" }}
              onClick={() => {
                setQuestionsBlock(true)
              }}
            >
              Edit
            </div>
            <div style={{ cursor: "pointer", display: "inline-block", margin: "5px", color: !QuestionsBlock ? "#000000" : "#444444", fontWeight: !QuestionsBlock ? "bold" : "normal" }}
              onClick={() => {
                setQuestionsBlock(false)
              }}
            >
              View
            </div>
          </div>
          {QuestionsBlock ?
            <div>
              {Object.keys(QuestionsData).length + 1 > 5 &&
                <div className="row center m-2">
                  <div className=" col-3" style={{ padding: "0 10px" }}>
                    <div className="sign-in-button-4"
                      onClick={() => {
                        var newQues = {
                          "number": ``,
                          "image": "",
                        }
                        var allQues = {
                          ...QuestionsData,
                          [`item-${Object.keys(QuestionsData).length}`]: newQues
                        }
                        setQuestionsData(allQues)
                      }}
                    >
                      Add Note
                    </div>
                  </div>
                </div>
              }
              <div>
                {Object.values(QuestionsData).map((oneQuestionData, index) => (
                  <section key={index} style={{
                    pageBreakInside: "avoid", display: "block", cursor: "pointer", padding: "3px 0",
                  }}>
                    <div className="row"
                      style={{
                        border: "1px solid #999",
                        borderRadius: "5px",
                        margin: "5px",
                        padding: "10px",
                        backgroundColor: "#fff",
                      }}>
                      <div
                        style={{ padding: "5px 0 0 0" }}
                      >
                        <h3 style={{ fontSize: "18px", fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                          {oneQuestionData.number}.
                        </h3>
                        <table>
                          <tbody>
                            <tr>
                              <td className="col-3">Number</td>
                              <td className="col-9">
                                <input className="input-common" style={{ width: "100%" }}
                                  value={oneQuestionData.number} placeholder="Number"
                                  onChange={(event) => {
                                    onChangeFunction("number", event.target.value, index)
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="col-3">Image</td>
                              <td className="col-9">
                                <ImageUploadAuto
                                  ImageUrl={oneQuestionData.image}
                                  onChange={onChangeFunction}
                                  type={"image"}
                                  index={index}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                    </div>
                  </section>
                ))}
              </div>
              <div className="row center m-2">
                <div className=" col-3" style={{ padding: "0 10px" }}>
                  <div className="sign-in-button-4"
                    onClick={() => {
                      var newQues = {
                        "number": ``,
                        "image": "",
                      }
                      var allQues = {
                        ...QuestionsData,
                        [`item-${Object.keys(QuestionsData).length}`]: newQues
                      }
                      setQuestionsData(allQues)
                    }}
                  >
                    Add Note
                  </div>
                </div>
              </div>
            </div>
            :
            <div>
              <div className="row">
                {Object.values(QuestionsData).map((oneQuestionData, index) => (
                  <section key={index} className="col-sm-6" style={{
                    pageBreakInside: "avoid", display: "block", cursor: "pointer", padding: "3px 0",
                  }}>
                    <div className="row"
                      style={{
                        border: "1px solid #999",
                        borderRadius: "5px",
                        margin: "5px",
                        padding: "10px",
                        backgroundColor: "#fff",
                      }}>
                      <div
                        style={{ padding: "5px 0 0 0" }}
                      >
                        <h3 style={{ fontSize: "18px", fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                          {oneQuestionData.number}
                        </h3>
                        <img src={oneQuestionData.image}
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                  </section>
                ))}
              </div>
            </div>
          }
          {Object.keys(QuestionsData).length + 1 > 5 &&
            <div style={{ display: "inline", justifyContent: "flex-end" }}>
              <div style={{ cursor: "pointer", display: "inline-block", margin: "5px", color: QuestionsBlock ? "#000000" : "#444444", fontWeight: QuestionsBlock ? "bold" : "normal" }}
                onClick={() => {
                  setQuestionsBlock(true)
                }}
              >
                Edit
              </div>
              <div style={{ cursor: "pointer", display: "inline-block", margin: "5px", color: !QuestionsBlock ? "#000000" : "#444444", fontWeight: !QuestionsBlock ? "bold" : "normal" }}
                onClick={() => {
                  setQuestionsBlock(false)
                }}
              >
                View
              </div>
            </div>
          }
        </div>
      </div>
        {reload &&
          <div className="modal" style={{ padding: "50vw" }}>
            <div className="dot-pulse"></div>
          </div>
        }
      </div>
    </div >
  )

}