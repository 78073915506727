import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsGETApiAuth, AdsPOSTApi, AdsPUTApi } from "../../../../Constant/api";
import { slugs } from "../../../../Constant/slugs";
import { AlertComponent } from "../../../AlertComponent";
import { CustomButton } from "../../../CustomButtom";
import { CustomButtonUnSelect } from "../../../CustomButtomUnselect";
import FileUpload from "../../../FileUpload";
import ImageUpload from "../../../ImageUpload";

export default function FormComponent(props) {
  const { FetchData } = props;
  const history = useHistory()
  const [ID, setID] = React.useState(FetchData.id)

  const [Title, setTitle] = React.useState(FetchData.title.rendered)
  const [Description, setDescription] = React.useState(FetchData.meta.description)
  const [FeaturedImage, setFeaturedImage] = React.useState(FetchData.meta.featured_image)
  const [ParentID, setParentID] = React.useState(FetchData.meta.parent_id)
  const [ParentName, setParentName] = React.useState(FetchData.meta.parent_name)
  const [SecondaryParentID, setSecondaryParentID] = React.useState(FetchData.meta.secondary_parent_id)
  const [SecondaryParentName, setSecondaryParentName] = React.useState(FetchData.meta.secondary_parent_name)
  const [SubParentName, setSubParentName] = React.useState(FetchData.meta.sub_parent_name)
  const [SubParentID, setSubParentID] = React.useState(FetchData.meta.sub_parent_id)
  const [TypeList, setTypeList] = React.useState([])
  const [SubTypeModal, setSubTypeModal] = React.useState(false)
  const [SecondaryTypeModal, setSecondaryTypeModal] = React.useState(false)
  const [Featured, setFeeatured] = React.useState("0")

  const [reload, setReload] = React.useState(false)

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")

  const EditInfo = () => {
    setReload(true)
    const data = {
      "title": Title,
      "status": "publish",
      "meta": {
        "description": Description,
        "featured_image": FeaturedImage,
        "featured": Featured
      }
    }

    if (ID === "")
      AdsPOSTApi(data, slugs.db_slug_exam_categories)
        .then((res) => {
          setReload(false)
          setID(res.data.id)
          setAlertShow(true)
          setAlertcomp("Book Added Successfully")
          history.push(`${slugs.exam_categories_details}${res.data.slug}`)
        }).catch(err => {
          setReload(false)
          console.log(err)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
    else
      AdsPUTApi(data, slugs.db_slug_exam_categories, ID)
        .then((res) => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(`${Title} edited Successfully`)
        }).catch(err => {
          console.log(err)
          console.warn(err.response.data)
          console.log("Response Status: hello11", err.response.status);
          console.log("Response Headers:", err.response.headers);
          setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
  }



  useEffect(() => {
    BatchList()
  }, [])
  const BatchList = () => {
    AdsGETApiAuth(
      { per_page: 1000, _fields: "id,title,meta" },
      slugs.db_slug_exam_categories
    )
      .then((res) => {
        setTypeList(res.data)
      })
      .catch(e => {
        console.log(e)
      })
  }


  return (
    <div>
      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Category Information</center></h4>
          <table>
            <tbody>
              <tr>
                <td className="col-3">Name</td>
                <td className="col-6">
                  <input className="input-common"
                    value={Title} placeholder="Name"
                    onChange={(event) => { setTitle(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Featured Image</td>
                <td className="col-6">
                  <ImageUpload
                    ImageUrl={FeaturedImage}
                    setImageUrl={setFeaturedImage}
                  />
                </td>
              </tr>
              <tr>
                <td >Featured:</td>
                <td >
                  <CustomButtonUnSelect setData={setFeeatured} Data={Featured} DataValue={"Featured"} />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  EditInfo()
                }}
              >
                Update Category
              </div>
            </div>
          </div>
        </div>
      </div>

      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {
        AlertShow &&
        <AlertComponent Alertcomp={Alertcomp} setAlertShow={setAlertShow} />
      }
    </div>
  )

}