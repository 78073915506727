import React from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsPOSTApi, AdsPUTApi } from "../../../../Constant/api";
import { slugs } from "../../../../Constant/slugs";
import { AlertComponent } from "../../../AlertComponent";
import { CustomButton } from "../../../CustomButtom";
import FileUpload from "../../../FileUpload";
import ExamQuestionModal from "./ExamQuestionModal";
import StudentExamModal from "./StudentExamModal";

export default function FormComponent(props) {
  const { FetchData } = props;
  const history = useHistory()
  const [ID, setID] = React.useState(FetchData.id)

  const [CourseType, setCourseType] = React.useState(FetchData.meta.course_type)
  const [BatchName, setBatchName] = React.useState(FetchData.meta.course_name_order)
  const [BatchId, setBatchID] = React.useState(FetchData.meta.course_id_order)
  const [AmountToPay, setAmountToPay] = React.useState(FetchData.meta.amount_to_pay)
  const [BatchModal, setBatchModal] = React.useState(false)
  const [StudentID, setStudentID] = React.useState(FetchData.meta.student_id_order)
  const [StudentEmail, setStudentEmail] = React.useState(FetchData.meta.student_email_order)
  const [StudentPhoneNumber, setStudentPhoneNumber] = React.useState(FetchData.meta.student_phone_order)
  const [StudentUserName, setStudentUserName] = React.useState(FetchData.meta.student_username_order)
  const [StudentModal, setStudentModal] = React.useState(false)
  const [PaidAmount, setPaidAmount] = React.useState(FetchData.meta.paid_amount)
  const [VerificationStatus, setVerificationStatus] = React.useState(FetchData.meta.verification_status)
  const [VerificationMethod, setVerificationMethod] = React.useState(FetchData.meta.verification_method)
  const [VerificationDate, setVerificationDate] = React.useState(FetchData.meta.verification_date)
  const [VerificationID, setVerificationID] = React.useState(FetchData.meta.verification_id)

  const [reload, setReload] = React.useState(false)

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")

  const EditInfo = () => {
    setReload(true)
    const data = {
      "title": `${StudentID}-${BatchId}`,
      "status": "publish",
      "meta": {
        "course_name_order": BatchName,
        "course_id_order": `${BatchId}`,
        "student_id_order": `${StudentID}`,
        "student_email_order": StudentEmail,
        "student_phone_order": `${StudentPhoneNumber}`,
        "student_username_order": StudentUserName,
        "paid_amount": `${PaidAmount}`,
        "amount_to_pay": `${AmountToPay}`,
        "verification_status": VerificationStatus,
        "verification_method": VerificationMethod,
        "verification_date": `${VerificationDate}`,
        "verification_id": `${VerificationID}`
      }
    }

    if (ID === "")
      AdsPOSTApi(data, slugs.db_slug_course_orders)
        .then((res) => {
          setReload(false)
          setID(res.data.id)
          setAlertShow(true)
          setAlertcomp("Order Added Successfully")
          history.push(`${slugs.course_order_details}${res.data.slug}`)
        }).catch(err => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
    else
      AdsPUTApi(data, slugs.db_slug_course_orders, ID)
        .then((res) => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(`Order edited Successfully`)
        }).catch(err => {
            setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
  }
  return (
    <div>
      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Order Information</center></h4>
          <table>
            <tbody>
              <tr >
                <td className="col-3">Course Name</td>
                <td className="col-6" style={{ cursor: "pointer" }}
                  onClick={() => { setBatchModal(true) }}>
                  <div className="input-common">
                    {BatchName}&nbsp;
                  </div>

                </td>
              </tr>
              <tr >
                <td className="col-3">Course Name</td>
                <td className="col-6" style={{ cursor: "pointer" }}
                  onClick={() => { setBatchModal(true) }}>
                  <div className="input-common">
                    {CourseType}&nbsp;
                  </div>

                </td>
              </tr>
              <tr>
                <td className="col-3">Course ID</td>
                <td className="col-6" style={{ cursor: "pointer" }}
                  onClick={() => { setBatchModal(true) }}>
                  <div className="input-common">
                    {BatchId}&nbsp;
                  </div>
                </td>
              </tr>
              <tr>
                <td className="col-3">Student ID</td>
                <td className="col-6" style={{ cursor: "pointer" }}
                  onClick={() => { setStudentModal(true) }}>
                  <div className="input-common">
                    {StudentID}&nbsp;
                  </div>
                </td>
              </tr>
              <tr>
                <td className="col-3">Student Name</td>
                <td className="col-6" style={{ cursor: "pointer" }}
                  onClick={() => { setStudentModal(true) }}>
                  <div className="input-common">
                    {StudentUserName}&nbsp;
                  </div>
                </td>
              </tr>
              <tr>
                <td className="col-3">Student Phone Number</td>
                <td className="col-6" style={{ cursor: "pointer" }}
                  onClick={() => { setStudentModal(true) }}>
                  <div className="input-common">
                    {StudentPhoneNumber}&nbsp;
                  </div>
                </td>
              </tr>
              <tr>
                <td className="col-3">Student Email</td>
                <td className="col-6" style={{ cursor: "pointer" }}
                  onClick={() => { setStudentModal(true) }}>
                  <div className="input-common">
                    {StudentEmail}&nbsp;
                  </div>
                </td>
              </tr>
              <tr>
                <td className="col-3">Paid Amount</td>
                <td className="col-6">
                  <input className="input-common"
                    value={PaidAmount} placeholder="Paid Amount"
                    onChange={(event) => { setPaidAmount(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Amount to Pay</td>
                <td className="col-6">
                  <div className="input-common">
                    {AmountToPay}&nbsp;
                  </div>
                </td>
              </tr>
              <tr>
                <td className="col-3">Verification Status</td>
                <td className="col-6">
                  <CustomButton setData={setVerificationStatus} Data={VerificationStatus} DataValue={"Unverified"} />
                  <CustomButton setData={setVerificationStatus} Data={VerificationStatus} DataValue={"Verified"} />
                </td>
              </tr>
              <tr>
                <td className="col-3">Verification Method</td>
                <td className="col-6">
                  <CustomButton setData={setVerificationMethod} Data={VerificationMethod} DataValue={"Esewa"} />
                  <CustomButton setData={setVerificationMethod} Data={VerificationMethod} DataValue={"Khalti"} />
                  <CustomButton setData={setVerificationMethod} Data={VerificationMethod} DataValue={"Manual"} />
                </td>
              </tr>
              <tr>
                <td className="col-3">Verification Date</td>
                <td className="col-6">
                  <input className="input-common"
                    value={VerificationDate} placeholder="Verification Date"
                    onChange={(event) => { setVerificationDate(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Verification ID</td>
                <td className="col-6">
                  {VerificationID.includes("https:") ?
                    <a href={VerificationID} target="_blank">
                      <img src={VerificationID} style={{ height: "200px" }} />
                    </a>
                    :
                    <input className="input-common"
                      value={VerificationID} placeholder="Verification ID"
                      onChange={(event) => { setVerificationID(event.target.value) }}
                    />
                  }
                </td>
              </tr>
            </tbody>
          </table>
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  EditInfo()
                }}
              >
                Update Order
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        BatchModal &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setBatchModal(false)
                  }}
                >X</div>
                <ExamQuestionModal
                  setExamName={setBatchName} setExamModal={setBatchModal}
                  ExamId={BatchId} setExamID={setBatchID}
                  setAmountToPay={setAmountToPay}
                  CourseType={CourseType} setCourseType={setCourseType}

                />
              </div>
            </div>
          </div>
        </section>
      }
      {
        StudentModal &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setStudentModal(false)
                  }}
                >X</div>
                <StudentExamModal
                  setStudentID={setStudentID}
                  setStudentEmail={setStudentEmail}
                  StudentID={StudentID}
                  setStudentPhoneNumber={setStudentPhoneNumber}
                  setStudentUserName={setStudentUserName}
                  setStudentModal={setStudentModal}
                />
              </div>
            </div>
          </div>
        </section>
      }
      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {
        AlertShow &&
        <AlertComponent Alertcomp={Alertcomp} setAlertShow={setAlertShow} />
      }
    </div>
  )

}