import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AdsGETApi, AdsGETApiAuth, AdsGETApiWithToken, UserMe } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import store from '../../../redux/store';
import NotFound from '../../NotFound';
import FirstScreenExam from '../ExamDetails';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
// import ContactUS from '../../ContactUS';

export default function ClassRoom(props) {
	const location = useLocation()
	const [ExamData, setAllExamList] = React.useState({});
	const [ExamFetch, setExamFetch] = React.useState(false);
	const [NotFoundData, setNotFound] = React.useState(false)
	const [reload, setReload] = React.useState(false)
	const [choose, setChoose] = React.useState(0)
	const [AnswerArray, setAnswerArray] = React.useState([]);
	const [ResultData, setResultData] = React.useState({});
	const [TeacherList, setTeacherList] = React.useState([]);

	const [userName, setUserName] = React.useState("")
	const [password, setPassword] = React.useState("")
	const [confirmPassword, setConfirmPassword] = React.useState("")

	
	const fetchExam = (slug) => {
		setExamFetch(false)
		setReload(true)
		setNotFound(false)
		const id = store.getState().LoginState.userID
		AdsGETApi({ per_page: 1 }, slugs.db_slug_demo_exam)
			.then((res) => {
				if (res.data.length == 1) {
					setAllExamList(res.data[0])
				}
				else {
					setNotFound(true)
				}
				setExamFetch(true)
				setReload(false)
			})
			.catch(err => {
				console.log(err)
				console.log(err.response.data)
			})

	}
	useEffect(() => {
		// fetchExam(location.pathname.slice(slugs.start_exam.length + 1))
		// GetTeacherList()
	}, [])
	const GetTeacherList = (slug) => {
		AdsGETApiWithToken({
			per_page: 100,
			"filter[meta_query][1][key]": "user_role",
			"filter[meta_query][1][value][0]": `teacher`,
			"filter[meta_query][1][compare]": "=",
		}, slugs.db_slug_user_data, "ZGVtb3VzZXI6MTIzMTIzMTIz")
			.then((res) => {
				setTeacherList(res.data[0])
				setReload(false)
			})
			.catch(err => {
				console.log(err)
				console.log(err.response.data)
			})
	}
	const history = useHistory()
	const UserInfo = () => {
		setReload(true)
		UserMe(window.btoa(`${userName}:${password}`))
			.then((res) => {
				AdsGETApiWithToken(
					{
						_fields: "id,meta",
						status: "any",
						"filter[meta_query][1][key]": "user_id",
						"filter[meta_query][1][value][0]": `${res.data.id}`,
						"filter[meta_query][1][compare]": "=",
					},
					slugs.db_slug_user_data, window.btoa(`${userName}:${password}`))
					.then((response) => {
						if (response.data[0].meta.user_role == "teacher") {
							const time = new Date();
							var Time = time.getTime()
							if (response.data[0].meta.user_featured_exam !== "") {
								localStorage.setItem('teacher_token', window.btoa(`${userName}:${password}`));
								localStorage.setItem('teacher_token_time', Time);
								history.push(`${slugs.start_class_exam}/${response.data[0].meta.user_featured_exam_slug}`)
							}
						}
					})
					.finally(() => {
						setReload(false)
					})
			})
	}
	return (
		<div style={{ backgroundColor: "#eee", minHeight: "90vh" }}>
			<div>
				<div className="row">
					<div className="col-sm-2 col-1"></div>
					<div className="col-sm-8 col-10 form-view m-3">
						<div className='center'>
							<div style={{ padding: "15px" }}>
								<h2>Join ClassRoom</h2>
							</div>
							<img src='https://statics.teams.cdn.office.net/hashed/join-code-icon-4f716a8.svg'
								style={{ width: "100px", height: "100px" }}
							/>
							<div style={{ fontSize: "14px", fontWeight: "bold" }}>Join Class</div>
							<div className="row">
								<div className="col-lg-3 col-1" />

								<div className="col-lg-6 col-10 px-5">

									<div className="label-form">Teacher Name.</div>
									<input
										className="col-12  input-style"
										type="text"
										value={userName}
										onChange={(event) => { setUserName(event.target.value) }}
										placeholder="Enter Teacher Name"
										required
									/><br />
									<div className="label-form">Class Code.</div>
									<input
										className="col-12  input-style"
										type={"text"}
										value={password}
										onChange={(event) => { setPassword(event.target.value) }}
										placeholder="Enter Class Code"
										required
									/><br />
									<button onClick={() => {
										UserInfo()
									}} className="sign-in-button-1 col-8">
										Start Exam
									</button>
									<br />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{reload &&
				<div className="modal" style={{ padding: "50vw" }}>
					<div className="dot-pulse"></div>
				</div>
			}
			{/* <ContactUS /> */}
		</div>
	)
}