import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { AdsGETApiAuth, AdsPUTApi } from "../../../Constant/api";
import { slugs } from "../../../Constant/slugs";
const columns = [
  {
    id: 1,
    name: "id",
    selector: (row) => row.id,
    sortable: true,
    reorder: true,
  },
  {
    id: 31,
    name: "Title",
    selector: (row) => row.title.rendered,
    sortable: true,
    reorder: true
  },
  {
    id: 32,
    name: "Exam Time",
    selector: (row) => row.meta.exam_time,
    sortable: true,
    reorder: true
  },
  {
    id: 33,
    name: "Availability",
    selector: (row) => row.meta.available_unavailable,
    sortable: true,
    reorder: true,
    width: "150px"
  },
  {
    id: 34,
    name: "Type",
    selector: (row) => row.meta.exam_type,
    sortable: true,
    reorder: true
  },
  {
    id: 34,
    name: "Status",
    selector: (row) => row.status == "draft" ? "Hidden" : "Active",
    sortable: true,
    reorder: true,
    width: "100px"
  },
  {
    id: 3,
    name: "View",
    right: true,
    cell: (row) => <Link to={`${slugs.demo_exam_details}${row.slug}`}>View</Link>,

  }
];

export default function AllDemoExam() {
  const [Projects1, setProjects1] = React.useState([])
  const [Projects2, setProjects2] = React.useState([])
  const [CustomersList, setCustomersList] = React.useState([])
  const [Reload, setReload] = React.useState(false)
  const GetAllProjects = (params) => {
    setReload(true)
    // setProjects1([])
    AdsGETApiAuth(params, slugs.db_slug_demo_exam)
      .then((res) => {
        setProjects2(res.data)
        setCustomersList(res.data)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  const GetProject = () => {
    GetAllProjects({
      per_page: 100,
      _fields: "id,title,status,slug,date,meta.exam_time,meta.available_unavailable,meta.exam_type",
      status: "any"
    })

  }
  useEffect(() => {
    GetProject()
  }, [])

  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>All Demo Exam</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / All Demo Exam
        </div>
      </div>
      <a href={slugs.new_demo_exam}>
        <div className="sign-in-button-4" style={{ width: "150px", margin: "10px" }}
          onClick={() => {

          }}
        >Add New Demo Exam</div>
      </a>
      <div style={{ display: "inline" }}>
        <div style={{ display: "inline-block", margin: "0px 5px" }}
          onClick={() => {
            setProjects2(CustomersList)
          }}
        >
          All ({CustomersList.length}) |
        </div>
        <div style={{ display: "inline-block", margin: "0px 5px" }}
          onClick={() => {
            setProjects2(CustomersList.filter(a => a.status == "draft"))
          }}
        >
          Hidden ({CustomersList.filter(a => a.status == "draft").length}) |
        </div>
      </div>

      {Projects1.filter(a => a.status == "publish").length !== 0 &&
        <div style={{ display: "inline-block", margin: "0px 5px" }}
          onClick={() => {
            for (let i = 0; i < Projects1.length; i++) {
              setReload(true)
              AdsPUTApi({
                status: "draft"
              }, slugs.db_slug_demo_exam, Projects1[i].id)
                .then(res => {
                  if (i == Projects1.length - 1) {
                    setProjects1([])
                    GetProject()
                  }
                })
            }
          }
          }
        >
          Hide |
        </div>
      }
      {Projects1.filter(a => a.status == "draft").length !== 0 &&
        <div style={{ display: "inline-block", margin: "0px 5px" }}
          onClick={() => {
            for (let i = 0; i < Projects1.length; i++) {
              setReload(true)
              AdsPUTApi({
                status: "publish"
              }, slugs.db_slug_demo_exam, Projects1[i].id)
                .then(res => {
                  if (i == Projects1.length - 1) {
                    setProjects1([])
                    GetProject()
                  }
                })
            }
          }
          }
        >
          UnHide |
        </div>
      }
      <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px" }}>
        <DataTable
          columns={columns}
          data={Projects2}
          defaultSortFieldId={1}
          defaultSortAsc={false}
          striped
          // sortIcon={<SortIcon />}
          pagination
          selectableRows
          onSelectedRowsChange={(r) => {
            console.log("asdasd", r)
            setProjects1(r.selectedRows)
          }}
        />
      </div>
      {Reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}