import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { AdsDeleteApi, AdsGETApiAuth, AdsPUTApi } from "../../../Constant/api";
import { slugs } from "../../../Constant/slugs";


export default function AllCourseOrders() {
  const [Projects1, setProjects1] = React.useState([])
  const [CustomersList, setCustomersList] = React.useState([])
  const [StudentsList, setStudentsList] = React.useState([])
  const [BatchList, setBatchList] = React.useState([])
  const [Reload, setReload] = React.useState(false)
  const [AllTotal, setAllTotal] = React.useState(0)
  const [serachStudent, setSearchStudent] = React.useState("")
  const [serachBatch, setSearchBatch] = React.useState("")
  const [serachBatchID, setSearchBatchID] = React.useState("")
  const [SubscriberTotal, setSubscriberTotal] = React.useState(0)
  const [StudentTotal, setStudentTotal] = React.useState(0)
  const [AdminTotal, setAdminTotal] = React.useState(0)
  const [BatchTotal, setBatchTotal] = React.useState(0)
  const [choose, setChoose] = React.useState(0)

  const GetAllProjects = (params, setData, slug, setTotal) => {
    setReload(true)
    setProjects1([])
    AdsGETApiAuth(params, slug)
      .then((res) => {
        setData(res.data)
        setTotal(res.headers["x-wp-total"])
        if (slug == slugs.db_slug_batch_orders)
          setCustomersList(res.data)
      })
      .catch(err => {
      }).finally(() => {
        setReload(false)
      })
  }

  useEffect(() => {
    GetAllProjects({
      per_page: 4900,
      _fields: "id,title,slug,meta"
    }, setProjects1, slugs.db_slug_course_orders, setAllTotal)
    GetAllProjects({
      per_page: 500,
      _fields: "id,title,slug,meta.course_name,meta.course_type,meta.course_price,meta.discount_price,date,modified"
    }, setBatchList, slugs.db_slug_course, setBatchTotal)
    GetAllProjects({
      per_page: 4900,
      _fields: "id,title,slug,meta.user_phone_number,meta.batch_name,meta.user_id"
    }, setStudentsList, slugs.db_slug_user_data, setStudentTotal)
  }, [])

  const columnBatch = [
    {
      id: 1,
      name: "id",
      selector: (row) => row.id,
      sortable: true,
      reorder: true,
      compact: true, padding: 0,
    },
    {
      id: 2,
      name: "Title",
      selector: (row) => row.title.rendered,
      sortable: true,
      reorder: true,
      compact: true, padding: 0,
    },
    {
      id: 3,
      name: "Price",
      selector: (row) => row.meta.course_price,
      sortable: true,
      center:true,
      reorder: true,
      compact: true, padding: 0,
    },
    {
      id: 4,
      name: "Discount",
      selector: (row) => row.meta.discount_price,
      sortable: true,
      compact: true, padding: 0,
      reorder: true
    },
    {
      id: 5,
      name: "Created",
      selector: (row) => row.date.slice(0,10),
      sortable: true,
      compact: true, padding: 0,
      reorder: true
    },
   {
      id: 6,
      name: "Modified",
      selector: (row) => row.modified.slice(0,10),
      sortable: true,
      reorder: true,
      width:"100px"
    }, 
    {
      id: 8,
      name: "Count",
      selector: (row) => CustomersList.filter(a => a.meta.course_id_order == row.id).length,
      sortable: true,
      compact: true, padding: 0,
      reorder: true,
      width:"100px"
    },
    {
      id: 9,
      name: "View",
      right: true,
      width:"100px",
      cell: (row) => <div style={{ cursor: "pointer" }}
      onClick={() => {
        setProjects1(CustomersList.filter(a => a.meta.course_id_order == row.id))
        setChoose(0)
      }}
    >
      Choose
    </div>,
  
    }
  ];
  const columnsStudent = [
    {
      id: 1,
      name: "id",
      selector: (row) => row.meta.user_id,
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Title",
      selector: (row) => row.title.rendered,
      sortable: true,
      reorder: true
    },
    {
      id: 3,
      name: "Phone",
      selector: (row) => row.meta.user_phone_number,
      sortable: true,
      reorder: true
    },
    {
      id: 5,
      name: "Total Courses",
      selector: (row) => CustomersList.filter(a => a.meta.student_id_order == row.meta.user_id).length,
      sortable: true,
      reorder: true
    },
    {
      id: 6,
      name: "Choose",
      right: true,
      cell: (row) => <div style={{ cursor: "pointer" }}
        onClick={() => {
          setProjects1(CustomersList.filter(a => a.meta.student_id_order == row.meta.user_id))
          setChoose(0)
        }}
      >
        Choose
      </div>,

    }
  ];
  const columns = [
    {
      id: 1,
      name: "id",
      selector: (row) => row.id,
      sortable: true,
      reorder: true,
      width: "80px",
      compact: true, padding: 0,
    },
    {
      id:2,
      name: "Batch",
      selector: (row) => row.meta.course_name_order,
      sortable: true,
      reorder: true,
      compact: true, padding: 0,
    },
    {
      id: 3,
      name: "Batch ID",
      selector: (row) => row.meta.course_id_order,
      sortable: true,
      reorder: true,
      compact: true,
      padding: 0,
    },
    {
      id: 4,
      name: "Student ID",
      selector: (row) => row.meta.student_username_order,
      sortable: true,
      center: true,
      reorder: true,
      compact: true,
      padding: 0,
    },
    {
      id: 5,
      name: "Phone No",
      selector: (row) => row.meta.student_phone_order,
      sortable: true,
      compact: true, padding: 0,
      reorder: true
    },
    {
      id: 6,
      name: "Verification",
      selector: (row) => row.meta.verification_status,
      sortable: true,
      reorder: true,
      compact: true,
      padding: 0,
    },
    {
      id: 7,
      name: "Amount",
      selector: (row) => row.meta.paid_amount,
      sortable: true,
      reorder: true,
      center: true,
      compact: true,
      padding: 0,
    },
    {
      id: 8,
      name: "Delete",
      center: true,
      compact: true,
      padding: 0,
      cell: (row) => <div style={{ cursor: "pointer" }}
        onClick={() => {
          AdsDeleteApi(slugs.db_slug_course_orders, row.id)
            .then((res) => {
              setSearchStudent("")
              setSearchBatch("")
              setSearchBatchID("")
              GetAllProjects({
                per_page: 4900,
                _fields: "id,title,slug,meta"
              }, setProjects1, slugs.db_slug_course_orders, setAllTotal)
            })
            .catch((err) => {
            })
        }}
      >Delete</div>,
    },
    {
      id: 32,
      name: "View",
      center: true,
      compact: true,
      padding: 0,
      cell: (row) => <Link to={`${slugs.course_order_details}${row.slug}`}>View</Link>,

    }
  ];
  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>All Course Orders</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / All Course Orders
        </div>
      </div>
      <a href={slugs.new_course_order}>
        <div className="sign-in-button-4" style={{ width: "150px", margin: "10px" }}
          onClick={() => {

          }}
        >Add New Course Order</div>
      </a>
      <div className="row">
        <div className="col-md-7" style={{ paddingLeft: "40px" }}>
          <div>
            <div style={{ display: "inline", cursor: "pointer" }}>
              <div style={{ display: "inline-block", margin: "0px 5px" }}
                onClick={() => {
                  setChoose(1)
                }}
              >
                Courses ({BatchTotal}) |
              </div>
              <div style={{ display: "inline-block", margin: "0px 5px" }}
                onClick={() => {
                  setChoose(2)
                }}
              >
                Students ({StudentTotal}) |
              </div>
              <div style={{ display: "inline-block", margin: "0px 5px" }}
                onClick={() => {
                  setChoose(0)
                  setProjects1(CustomersList)
                }}
              >
                Clear |
              </div>
            </div>
          </div>
          <div>
            <div style={{ display: "inline", cursor: "pointer" }}>
              <div style={{ display: "inline-block", margin: "0px 5px" }}
                onClick={() => {
                  setChoose(0)
                  setProjects1(CustomersList)
                }}
              >
                All Orders ({AllTotal}) |
              </div>
              <div style={{ display: "inline-block", margin: "0px 5px" }}
                onClick={() => {
                  setChoose(0)
                  setProjects1(CustomersList.filter(a => a.meta.verification_status == "Verified"))
                }}
              >
                Verified ({CustomersList.filter(a => a.meta.verification_status == "Verified").length}) |
              </div>
              <div style={{ display: "inline-block", margin: "0px 5px" }}
                onClick={() => {
                  setChoose(0)
                  setProjects1(CustomersList.filter(a => a.meta.verification_status == "Unverified"))
                }}
              >
                Unverified ({CustomersList.filter(a => a.meta.verification_status == "Unverified").length}) |
              </div>
              <div style={{ display: "inline-block", margin: "0px 5px" }}
                onClick={() => {
                  setChoose(0)
                  setProjects1(CustomersList.filter(a => a.meta.verification_status == "Unverified" && a.meta.verification_method == "Manual"))
                }}
              >
                Manual ({CustomersList.filter(a => a.meta.verification_status == "Unverified" && a.meta.verification_method == "Manual").length}) |
              </div>
            </div>
          </div>

        </div>
        <div className="col-md-5 row">
          <div className="col-4" />
          <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
            value={serachBatch} placeholder="Batch Name"
            onChange={(event) => {
              setSearchBatch(event.target.value)
              setProjects1(CustomersList.filter(a => a.meta.batch_name_results.includes(event.target.value)))
            }}
          />
          <input className="col-2" style={{ margin: "1px 2px", padding: "3px 2px" }}
            value={serachBatchID} placeholder="Batch ID"
            onChange={(event) => {
              setSearchBatchID(event.target.value)
              setProjects1(CustomersList.filter(a => a.meta.batch_id_order.includes(event.target.value)))
            }}
          />
          <input className="col-2" style={{ margin: "1px 2px", padding: "3px 2px" }}
            value={serachStudent} placeholder="Student Phone"
            onChange={(event) => {
              setSearchStudent(event.target.value)
              setProjects1(CustomersList.filter(a => a.meta.student_phone_order.includes(event.target.value)))
            }}
          />
        </div>
      </div>


      <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px" }}>
        {choose == 0 &&
          <DataTable
            columns={columns}
            data={Projects1}
            defaultSortFieldId={1}
            defaultSortAsc={false}
            striped
            // sortIcon={<SortIcon />}
            pagination
            selectableRows
          />
        }
        {choose == 1 &&
          <DataTable
            columns={columnBatch}
            data={BatchList}
            defaultSortFieldId={1}
            defaultSortAsc={false}
            striped
            // sortIcon={<SortIcon />}
            pagination
            selectableRows
          />
        }
        {choose == 2 &&
          <DataTable
            columns={columnsStudent}
            data={StudentsList}
            defaultSortFieldId={1}
            defaultSortAsc={false}
            striped
            // sortIcon={<SortIcon />}
            pagination
            selectableRows
          />
        }
      </div>
      {Reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}