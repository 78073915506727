import React, { useEffect, ClipboardEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { AdsGETApiAuth, AdsGETApiUser, NodePOSTApi } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import store from '../../../redux/store';
import NotFound from '../../NotFound';
import FirstScreenExam from '../ExamDetails';

export default function StartExam(props) {
  const dispatch = useDispatch()
  const location = useLocation()
  const [ExamData, setAllExamList] = React.useState({});
  const [ExamFetch, setExamFetch] = React.useState(false);
  const [NotFoundData, setNotFound] = React.useState(false)
  const [reload, setReload] = React.useState(false)
  const [choose, setChoose] = React.useState(0)
  const [AnswerArray, setAnswerArray] = React.useState([]);
  const [ResultData, setResultData] = React.useState({});
  const history = useHistory()
  const [Student, setStudent] = React.useState(false);
  // const preventCopyPaste = (e) => {
  //   e.preventDefault()
  //   alert("Copying and pasting is not allowed!")
  // }
  const fetchExam = (slug, student) => {
    setExamFetch(false)
    setReload(true)
    setNotFound(false)
    const id = store.getState().LoginState.userID
    const student1 = student == "student" || student == "tabuser" ? "student" : ""

    NodePOSTApi({ userID: id, examSlug: slug, student: student1 }, "start-exam-details")
      .then(res => {
        console.log(res.data)
        if (res.data.redirect) {
          history.push(`${slugs.exam_payment}/${slug}`)
        }
        else {
          if (student !== "tabuser")
            if (res.data.result_data.length !== 0) {
              setChoose(2)
              console.log(res.data.result_data[0])
              setAnswerArray(JSON.parse(res.data.result_data[0].meta.exam_answers_array_results))
              setResultData(res.data.result_data[0])
            }
          setAllExamList(res.data.exam_data[0])
          setExamFetch(true)
        }
      })
      .finally(() => {
        setReload(false)
      })
      .catch(err => {
        console.log(err)
        console.log(err.response.data)
      })

  }
  const [finishStatus, setfinishStatus] = React.useState(false);

  useEffect(() => {
    // preventCopyPaste()
    AdsGETApiUser().then(res => {
      if (res.data.length == 0) {
        // logOutDisratch(dispatch)
      }
      else
        setStudent(res.data[0].meta.user_role == "student" || res.data[0].meta.user_role == "tabuser")
      fetchExam(location.pathname.slice(slugs.start_exam.length + 1), res.data[0].meta.user_role)
    })
  }, [])

  return (
    <div style={{ backgroundColor: "#eee", minHeight: "90vh" }}>
      <div>
        <div className="row">
          <div className=""></div>
          <div className=" col-12 form-view m-3">
            {ExamFetch &&
              <>
                {NotFoundData ?
                  <NotFound /> :
                  <>
                    <FirstScreenExam
                      ExamData={ExamData}
                      setReload={setReload}
                      choose={choose}
                      setChoose={setChoose}
                      AnswerArray={AnswerArray}
                      setAnswerArray={setAnswerArray}
                      ResultData={ResultData} setResultData={setResultData}
                    />
                  </>
                }
              </>
            }
          </div>
        </div>
      </div>
      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}