import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AdsGETApiAuth, AdsGETApiUser, AdsGETApiWithToken } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import store from '../../../redux/store';
import NotFound from '../../NotFound';
import FirstScreenExam from './FirstScreen';
import { useHistory } from 'react-router-dom';

export default function StartClassExam(props) {
  const dispatch = useDispatch()
  const location = useLocation()
  const [ExamData, setAllExamList] = React.useState({});
  const [ExamFetch, setExamFetch] = React.useState(false);
  const [NotFoundData, setNotFound] = React.useState(false)
  const [reload, setReload] = React.useState(false)
  const [choose, setChoose] = React.useState(0)
  const [AnswerArray, setAnswerArray] = React.useState([]);
  const [ResultData, setResultData] = React.useState({ meta: { exam_date_results: "" } });
  const token = localStorage.getItem('teacher_token');
  const history = useHistory()

  const fetchExam = (slug, role) => {
    setExamFetch(false)
    setReload(true)
    setNotFound(true)
    const id = store.getState().LoginState.userID

    AdsGETApiWithToken({ slug: slug }, slugs.db_slug_paid_exam, token)
      .then((res) => {
        if (res.data.length == 1) {
          if (role == "tabuser") {
            if (res.data[0].meta.exam_question_block_student != undefined) {
              setAllExamList(res.data[0])
              setExamFetch(true)
              console.log("asdsad")
              setNotFound(false)
            }
            else {
              console.log("asdsad")
              setExamFetch(true)
              setNotFound(true)
            }
            setExamFetch(true)
          }
          else
            AdsGETApiAuth({
              "filter[meta_query][1][key]": "exam_id_results",
              "filter[meta_query][1][value][0]": res.data[0].id,
              "filter[meta_query][1][compare]": "=",
              "filter[meta_query][relation]": "AND",
              "filter[meta_query][2][key]": "student_id_results",
              "filter[meta_query][2][value][0]": id,
              "filter[meta_query][2][compare]": "=",
            }, slugs.db_slug_classroom_results)
              .then((response) => {
                if (response.data.length !== 0) {
                  console.log(44)
                  setChoose(2)
                  setAnswerArray(JSON.parse(response.data[0].meta.exam_answers_array_results))
                  setResultData(response.data[0])
                }
                if (res.data[0].meta.exam_question_block_student != undefined) {
                  setAllExamList(res.data[0])
                  setExamFetch(true)
                  console.log("asdsad")
                  setNotFound(false)
                }
                else {
                  console.log("asdsad")
                  setExamFetch(true)
                  setNotFound(true)
                }
                setExamFetch(true)
              })
              .finally(() => {
                setReload(false)
              })
        }
        else {
          setExamFetch(true)
          console.log("asdsad")
          setNotFound(true)
        }
        setReload(false)
      })
      .catch(err => {
        console.log(err)
        console.log(err.response.data)
        history.pushState(slugs.classroom)
      })

  }
  const [finishStatus, setfinishStatus] = React.useState(false);
  const [TabUser, setTabUser] = React.useState(false);

  useEffect(() => {
    AdsGETApiUser().then(res => {
      if (res.data.length == 0) {
        // logOutDisratch(dispatch)
      }
      else {
        setTabUser(res.data[0].meta.user_role == "tabuser")
        // console.log(res.data[0].meta.user_role)
        fetchExam(location.pathname.slice(slugs.start_class_exam.length + 1), res.data[0].meta.user_role)
      }
    })
  }, [])

  return (
    <div style={{ backgroundColor: "#eee", minHeight: "90vh" }}>
      <div>
        <div className="row">
          <div className="form-view" style={{ width: "102vw" }}>
            {ExamFetch &&
              <>
                {NotFoundData ?
                  <NotFound /> :
                  <>
                    {/* {JSON.stringify(ExamData.meta.exam_question_block_text)} */}
                    <FirstScreenExam
                      ExamData={ExamData}
                      setReload={setReload}
                      choose={choose}
                      setChoose={setChoose}
                      AnswerArray={AnswerArray}
                      setAnswerArray={setAnswerArray}
                      ResultData={ResultData} setResultData={setResultData}
                    />

                  </>
                }
              </>
            }
          </div>
        </div>
      </div>
      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}