import React, { useEffect, useRef } from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import CloudPdfViewer from "@cloudpdf/viewer";

export default function AllBooksDetails(props) {
  const { navigation } = props;
  // const { type, title } = props.route.params;
  const [AllExamList1, setAllExamList1] = React.useState({});
  const [reload, setReload] = React.useState(true)
  const location = useLocation()
  const [PreviewPressed, setPreviewPressed] = React.useState(false)
  const [PreviewUrl, setPreviewUrl] = React.useState("https://api.dreamkoreaubttest.com/wp-content/uploads/2024/01/361-to480-1.pdf")
  const [PreviewType, setPreviewType] = React.useState("")
  const [PreviewObj, setPreviewObj] = React.useState({})
  const [PenModal, setPenModal] = React.useState(false)
  const viewer = useRef(null);
  useEffect(() => {
    CloudPdfViewer(
      {
        documentId: "2307a7a2-c198-44da-9411-9859e5492787",
        darkMode: true
      },
      viewer.current
    ).then((instance) => { });
  }, []);
  return (
    <div>
      <embed style={{ width: "100%", height: "85vh" }}
        src={PreviewUrl}
      //  height="100%" width="100%" 
      />
      <div>
        <div className="viewer" ref={viewer}  style={{height:"85vh"}}></div>
      </div>
    </div>
  )
}

const styles = {
  border: '0.0625rem solid #9c9c9c',
  borderRadius: '0.25rem',
};