import logo from './logo.svg';
import './App.css';
import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useLocation
} from "react-router-dom";
import store from './redux/store';

import { getUser } from './redux/action';
import { Provider, useDispatch, useSelector } from 'react-redux';
import Header from './Path/Header';
import NotFound from './Path/NotFound';
import SideBar from './Path/SideBar';

import AllBooks from './Path/Admin/Book';
import NewBook from './Path/Admin/Book/BookAddNew';
import BookDetails from './Path/Admin/Book/BookDetails';
import { slugs } from './Constant/slugs';
import AllChapters from './Path/Admin/ChapterWiseExam';
import AllDailyExam from './Path/Admin/DailyExam';
import AllDailyResults from './Path/Admin/DailyResults';
import AllDemoExam from './Path/Admin/DemoExam';
import AllExam from './Path/Admin/Exam';
import AllResults from './Path/Admin/Results';
import ChaptersDetails from './Path/Admin/ChapterWiseExam/ChapterDetails';
import DailyExamDetails from './Path/Admin/DailyExam/DailyExamDetails';
import DailyResultDetails from './Path/Admin/DailyResults/ResultsDetails';
import DemoExamDetails from './Path/Admin/DemoExam/DemoExamDetails';
import ExamDetails from './Path/Admin/Exam/ExamDetails';
import ResultDetails from './Path/Admin/Results/ResultsDetails';
import NewChapter from './Path/Admin/ChapterWiseExam/ChapterAddNew';
import NewDailyExam from './Path/Admin/DailyExam/DailyExamAddNew';
import NewDemoExam from './Path/Admin/DemoExam/DemoExamAddNew';
import NewExam from './Path/Admin/Exam/ExamAddNew';
import AllStudents from './Path/Admin/Students';
import StudentDetails from './Path/Admin/Students/StudentDetails';
import NewStudent from './Path/Admin/Students/StudentAddNew';
import AllPaidExamList from './Path/Student/Exam';
import AllDemoExamList from './Path/Student/DemoExam';
import AllChpaterExamList from './Path/Student/ChapterWiseExam';
import AllDailyExamList from './Path/Student/DailyExam';
import LoginScreen from './Path/SignIn';
import StartExam from './Path/Student/StartExam';
import StartDemoExam from './Path/Student/StartDemoExam';
import ExamResult from './Path/Student/ExamResult';
import StartDailyExam from './Path/Student/StartDailyExam';
import DailyExamResult from './Path/Student/ExamResult/DailyExam';
import ExamAllResult from './Path/Student/AllResults';
import DailyExamAllResult from './Path/Student/AllResults/DailyResults';
import StartChapterExam from './Path/Student/ChapterWiseExam/StartExam';
import Profile from './Path/Profile';
import AllBatch from './Path/Admin/Batch';
import BatchDetails from './Path/Admin/Batch/BatchDetails';
import NewBatch from './Path/Admin/Batch/BatchAddNew';
import ExamResultDetailsAdmin from './Path/Admin/Results/ResultsDetails';
import ChapterExamAllResult from './Path/Student/AllResults/ChapterResults';
import ChapterExamResult from './Path/Student/ExamResult/ChapterWiseResult';
import AllChapterResults from './Path/Admin/ChapterResults';
import ChapterExamResultDetailsAdmin from './Path/Admin/ChapterResults/ResultsDetails';
import AllBuyExam from './Path/Admin/BuyExam';
import AllBuyResults from './Path/Admin/BuyResults';
import BuyExamDetails from './Path/Admin/BuyExam/BuyExamDetails';
import BuyExamResultDetailsAdmin from './Path/Admin/BuyResults/ResultsDetails';
import NewBuyExam from './Path/Admin/BuyExam/BuyExamAddNew';

import AllPaidExamOrders from './Path/Admin/ExamOrders';
import AllBatchOrders from './Path/Admin/BatchOrders';
import NewBatchOrder from './Path/Admin/BatchOrders/BatchOrderNew';
import BatchOrdersDetails from './Path/Admin/BatchOrders/BatchOrderDetails';
import PaidExamOrdersDetails from './Path/Admin/ExamOrders/ExamOrderDetails';
import NewPaidExamOrder from './Path/Admin/ExamOrders/ExamOrderNew';

import BatchPayment from './Path/Student/DailyExam/BatchPayment';
import BatchExam from './Path/Student/DailyExam/BatchDetails';
import ExamExamPayment from './Path/Student/Exam/ExamPayment';
import AllTeacher from './Path/Admin/Teacher';
import NewTeacher from './Path/Admin/Teacher/TeacherAddNew';
import TeacherDetails from './Path/Admin/Teacher/TeacherDetails';
import StartClassExam from './Path/Student/ClassRoom/ClassExam';
import ClassRoom from './Path/Student/ClassRoom';
import DailyResultDetailsAdmin from './Path/Admin/DailyResults/ResultsDetails';
import MyExamResult from './Path/Student/ExamResult/MyExamResults';
import MyExamAllResult from './Path/Student/AllResults/BuyResults';
import PaymentVerificationKhalti from './Path/Student/Exam/PaymentVerificationKhalti';
import PaymentVerification from './Path/Student/Exam/PaymentVerification';
import PaymentVerificationBatch from './Path/Student/DailyExam/PaymentVerificationEsewaBatch';
import PaymentVerificationKhaltiBatch from './Path/Student/DailyExam/PaymentVerificationKhalti';
import AllClassRoomResults from './Path/Admin/ResultsClassroom';
import ClassroomResultDetailsAdmin from './Path/Admin/ResultsClassroom/ResultsDetails';
import ClassExamResult from './Path/Student/ExamResult/ClassResult';
import ClassExamAllResult from './Path/Student/AllResults/ClassResults';
import AllCourseOrders from './Path/Admin/CourseOrder';
import CourseOrdersDetails from './Path/Admin/CourseOrder/CourseOrderDetails';
import NewCourseOrder from './Path/Admin/CourseOrder/CourseOrderNew';
import AllCourse from './Path/Admin/Course';
import CourseDetails from './Path/Admin/Course/CourseDetails';
import NewCourse from './Path/Admin/Course/CourseAddNew';
import AllPaidCourseList from './Path/Student/Course';
import CoursePayment from './Path/Student/Course/CoursePayment';
import StartCourse from './Path/Student/Course/CourseView';
import CoursePaymentVerification from './Path/Student/Course/PaymentVerification';
import AllQuestionBankExam from './Path/Admin/QuestionBank';
import QuestionBankDetails from './Path/Admin/QuestionBank/QuestionBankDetails';
import NewQuestionBankExam from './Path/Admin/QuestionBank/QuestionBankAddNew';
import AllQuestionBankList from './Path/Student/QuestionBank';
import StartQuestinBankExam from './Path/Student/StartQuestionBank';
import AllExamCategories from './Path/Admin/Categories';
import CategoryDetails from './Path/Admin/Categories/CategoryDetails';
import NewCategories from './Path/Admin/Categories/CategoriesAddNew';
import QuestionBankPaymentVerification from './Path/Student/QuestionBank/PaymentVerification';
import QuestionBankPaymentVerificationKhalti from './Path/Student/QuestionBank/PaymentVerificationKhalti';
import QuestionBankExamExamPayment from './Path/Student/QuestionBank/ExamPayment';
import AllQuestionBankExamOrders from './Path/Admin/QuestionBankOrder';
import QuestionBankExamOrdersDetails from './Path/Admin/QuestionBankOrder/ExamOrderDetails';
import NewQuestionBankExamOrder from './Path/Admin/QuestionBankOrder/ExamOrderNew';
import AllBooksDetails from './Path/pdfViewer';
import ColorVisionAdmin from './Path/Admin/ColorVision';
import ColorVision from './Path/Student/ ColorVisionSt';


export default class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <AppMain />
        </Router>
      </Provider>
    );
  }
}

function AppMain() {
  const [loginState, setLoginState] = React.useState(true)
  const dispatch = useDispatch()
  const myState = useSelector((state) => state.LocationState);
  const Login = useSelector((state) => state.LoginState);
  const location = useLocation()

  const [menuExpand, setMenuExpand] = React.useState(false)
  useEffect(() => {
    dispatch(getUser())
    setLoginState(Login.isLoading)
  }, [])
  return (
    <div className="overflow-x-hidden" >
      {Login.token == null ?
        <LoginScreen />
        :
        <div className="row" >
          {/* {(location.pathname.includes("start") || location.pathname.includes("course-view")) ? <></> : */}
          {(location.pathname.includes("start")) ? <></> :
            <div className="sidebar-width-expand" style={{ borderRight: "3px solid #e4e4e4" }} >
              <div className={"sidebar-design"}>
                <div style={{ backgroundColor: "#fff" }} >
                  <SideBar menuExpand={menuExpand} />
                </div>
              </div>
            </div>
          }

          {menuExpand &&
            <div className="sidebar-small-screen ">
              <div className={"sidebar-design"} >
                <SideBar setMenuExpand={setMenuExpand} />
              </div>
            </div>
          }

          <div className={
            // location.pathname.includes("start") || location.pathname.includes("course-view")
            location.pathname.includes("start") 
              ? "main-width-full" : "main-width-expand"}
            style={{ backgroundColor: "#f3f3f3" }}>
            {!location.pathname.includes("start") &&
          // {(location.pathname.includes("start") || location.pathname.includes("course-view")) ? <></> :
              <Header setMenuExpand={setMenuExpand} menuExpand={menuExpand} />
            }
            <div>
              {Login.userRole == "author" &&
                <Switch>
                  <Route exact path="/"><AllExam /></Route>

                  <Route exact path="/profile"><Profile /></Route>
                  <Route path={slugs.all_books}><AllBooks /></Route>
                  <Route path={slugs.all_chapters}><AllChapters /></Route>
                  <Route path={slugs.all_daily_exam}><AllDailyExam /></Route>
                  <Route path={slugs.all_daily_results}><AllDailyResults /></Route>
                  <Route path={slugs.all_demo_exam}><AllDemoExam /></Route>
                  <Route path={slugs.all_exam}><AllExam /></Route>
                  <Route path={slugs.all_results}><AllResults /></Route>
                  <Route path={slugs.all_chapter_results}><AllChapterResults /></Route>
                  <Route path={slugs.all_classroom_results}><AllClassRoomResults /></Route>
                  <Route path={slugs.all_students}><AllStudents /></Route>
                  <Route path={slugs.all_batch}><AllBatch /></Route>
                  <Route path={"/AllBooksDetails"}><AllBooksDetails /></Route>
                  <Route path={"/color-vision-admin"}><ColorVisionAdmin /></Route>
                  
                  <Route path={slugs.all_question_bank_orders}><AllQuestionBankExamOrders /></Route>
                  <Route path={`${slugs.question_bank_order_details}:slug`}><QuestionBankExamOrdersDetails /></Route>
                  <Route path={slugs.new_question_bank_order}><NewQuestionBankExamOrder /></Route>


                  <Route path={slugs.all_question_bank}><AllQuestionBankExam /></Route>
                  <Route path={`${slugs.question_bank_details}:slug`}><QuestionBankDetails /></Route>
                  <Route path={slugs.new_question_bank}><NewQuestionBankExam /></Route>

                  <Route path={slugs.all_exam_categories}><AllExamCategories /></Route>
                  <Route path={`${slugs.exam_categories_details}:slug`}><CategoryDetails /></Route>
                  <Route path={slugs.new_exam_categories}><NewCategories /></Route>

                  <Route path={slugs.all_teachers}><AllTeacher /></Route>
                  <Route path={`${slugs.teacher_details}:slug`}><TeacherDetails /></Route>
                  <Route path={slugs.new_teacher}><NewTeacher /></Route>

                  <Route path={slugs.all_course_orders}><AllCourseOrders /></Route>
                  <Route path={`${slugs.course_order_details}:slug`}><CourseOrdersDetails /></Route>
                  <Route path={slugs.new_course_order}><NewCourse /></Route>

                  <Route path={slugs.all_course}><AllCourse /></Route>
                  <Route path={`${slugs.course_details}:slug`}><CourseDetails /></Route>
                  <Route path={slugs.new_course}><NewCourse /></Route>


                  <Route path={slugs.all_buy_exam}><AllBuyExam /></Route>
                  <Route path={`${slugs.buy_exam_details}:slug`}><BuyExamDetails /></Route>
                  <Route path={slugs.all_buy_results}><AllBuyResults /></Route>
                  <Route path={`${slugs.buy_exam_result_admin_view}/:slug`}><BuyExamResultDetailsAdmin /></Route>
                  <Route path={slugs.new_buy_exam}><NewBuyExam /></Route>

                  <Route path={slugs.all_orders}><AllPaidExamOrders /></Route>
                  <Route path={slugs.all_batch_orders}><AllBatchOrders /></Route>
                  <Route path={`${slugs.order_details}:slug`}><PaidExamOrdersDetails /></Route>
                  <Route path={`${slugs.batch_order_details}:slug`}><BatchOrdersDetails /></Route>
                  <Route path={slugs.new_order}><NewPaidExamOrder /></Route>
                  <Route path={slugs.new_batch_order}><NewBatchOrder /></Route>

                  <Route path={`${slugs.books_details}:slug`}><BookDetails /></Route>
                  <Route path={`${slugs.chapter_details}:slug`}><ChaptersDetails /></Route>
                  <Route path={`${slugs.daily_exam_details}:slug`}><DailyExamDetails /></Route>
                  <Route path={`${slugs.daily_result_details}:slug`}><DailyResultDetails /></Route>
                  <Route path={`${slugs.demo_exam_details}:slug`}><DemoExamDetails /></Route>
                  <Route path={`${slugs.exam_details}:slug`}><ExamDetails /></Route>
                  <Route path={`${slugs.result_details}:slug`}><ResultDetails /></Route>
                  <Route path={`${slugs.classroom_result_details}:slug`}><ClassroomResultDetailsAdmin /></Route>
                  <Route path={`${slugs.student_details}:slug`}><StudentDetails /></Route>
                  <Route path={`${slugs.batch_details}:slug`}><BatchDetails /></Route>

                  <Route path={slugs.new_book}><NewBook /></Route>
                  <Route path={slugs.new_chapter_wise_exam}><NewChapter /></Route>
                  <Route path={slugs.new_daily_exam}><NewDailyExam /></Route>
                  <Route path={slugs.new_demo_exam}><NewDemoExam /></Route>
                  <Route path={slugs.new_exam}><NewExam /></Route>
                  <Route path={slugs.new_student}><NewStudent /></Route>
                  <Route path={slugs.new_batch}><NewBatch /></Route>

                  <Route path={slugs.exam}><AllPaidExamList /></Route>
                  <Route path={slugs.question_bank_exam}><AllQuestionBankList /></Route>
                  <Route path={slugs.demo_exam}><AllDemoExamList /></Route>
                  <Route path={slugs.chapter_wise_exam}><AllChpaterExamList /></Route>
                  <Route path={slugs.daily_exam}><AllDailyExamList /></Route>
                  <Route path={slugs.course}><AllPaidCourseList /></Route>
                  <Route path={slugs.course_payment}><CoursePayment /></Route>
                  <Route path={slugs.start_course}><StartCourse /></Route>
                  <Route path={`/course_payment/:id`}><CoursePaymentVerification /></Route>
                  
                  <Route path={slugs.question_bank_exam_payment}><QuestionBankExamExamPayment /></Route>
                  <Route path={`/question_bank_payment/:id`}><QuestionBankPaymentVerification /></Route>
                  <Route path={`/question_bank_payment_khalti/:id`}><QuestionBankPaymentVerificationKhalti /></Route>

                  <Route path={`${slugs.start_exam}/:slug`}><StartExam /></Route>
                  <Route path={`${slugs.start_question_bank}/:slug`}><StartQuestinBankExam /></Route>
                  <Route path={`${slugs.start_demo_exam}/:slug`}><StartDemoExam /></Route>
                  <Route path={`${slugs.start_daily_exam}/:slug`}><StartDailyExam /></Route>
                  <Route path={`${slugs.start_chapter_wise_exam}/:slug`}><StartChapterExam /></Route>
                  {/* <Route path={slugs.all_live_exam_admin}><AllExamListLive /></Route>
                  <Route path={slugs.start_live_exam_admin}><StartExamAdmin /></Route> */}
                  <Route path={"/start-color-vision"}><ColorVision /></Route>

                  <Route path={`/batch_payment/:id`}><PaymentVerificationBatch /></Route>
                  <Route path={`/batch_payment_khalti/:id`}><PaymentVerificationKhaltiBatch /></Route>

                  <Route path={`${slugs.batch_exam}/:id`}><BatchExam /></Route>
                  <Route path={`${slugs.batch_payment}/:id`}><BatchPayment /></Route>
                  {/* <Route path={`/exam_payment/:id`}><PaymentVerification /></Route> */}
                  <Route path={`${slugs.exam_payment}/:id`}><ExamExamPayment /></Route>
                  <Route path={`/exam_payment_khalti/:id`}><PaymentVerificationKhalti /></Route>
                  <Route path={`/exam_payment/:id`}><PaymentVerification /></Route>

                  <Route path={`${slugs.buy_exam_result}/:id`}><MyExamResult /></Route>
                  <Route path={slugs.buy_exam_result_list}><MyExamAllResult /></Route>

                  <Route exact path={slugs.classroom}><ClassRoom /></Route>
                  <Route exact path={`${slugs.start_class_exam}/:id`}><StartClassExam /></Route>

                  <Route path={`${slugs.all_chapter_results}/:slug`}><ExamResultDetailsAdmin /></Route>
                  <Route path={`${slugs.result_admin_view}/:slug`}><ExamResultDetailsAdmin /></Route>
                  <Route path={`${slugs.chapter_result_admin_view}/:slug`}><ChapterExamResultDetailsAdmin /></Route>
                  <Route path={`${slugs.daily_result_admin_view}/:slug`}><DailyResultDetailsAdmin /></Route>
                  <Route path={slugs.exam_result}><ExamResult /></Route>
                  <Route path={slugs.class_result}><ClassExamResult /></Route>
                  <Route path={slugs.daily_exam_result}><DailyResultDetails /></Route>
                  <Route path={slugs.classroom_exam_result_list}><ClassExamAllResult /></Route>

                  <Route path={slugs.chapter_exam_result}><ChapterExamResult /></Route>
                  <Route path={slugs.chapter_wise_exam_result_list}><ChapterExamAllResult /></Route>

                  <Route path={slugs.exam_result_list}><ExamAllResult /></Route>
                  <Route path={slugs.daily_exam_result_list}><DailyExamAllResult /></Route>

                  <Route path="*"><NotFound /></Route>
                </Switch>
              }
              {Login.userRole != "author" &&
                <Switch>
                  <Route exact path="/"><AllPaidExamList /></Route>
                  <Route exact path="/profile"><Profile /></Route>

                  <Route path={slugs.exam}><AllPaidExamList /></Route>
                  <Route path={slugs.demo_exam}><AllDemoExamList /></Route>
                  <Route path={slugs.chapter_wise_exam}><AllChpaterExamList /></Route>
                  <Route path={slugs.daily_exam}><AllDailyExamList /></Route>
                  <Route path={`/batch_payment/:id`}><PaymentVerificationBatch /></Route>
                  <Route path={`/batch_payment_khalti/:id`}><PaymentVerificationKhaltiBatch /></Route>
                  <Route path={`/exam_payment_khalti/:id`}><PaymentVerificationKhalti /></Route>
                  <Route path={`/exam_payment/:id`}><PaymentVerification /></Route>
                  <Route path={slugs.question_bank_exam}><AllQuestionBankList /></Route>
                  <Route path={`${slugs.start_question_bank}/:slug`}><StartQuestinBankExam /></Route>
                  <Route path={"/start-color-vision"}><ColorVision /></Route>

                  <Route path={`${slugs.batch_exam}/:id`}><BatchExam /></Route>
                  <Route path={`${slugs.batch_payment}/:id`}><BatchPayment /></Route>
                  <Route path={`${slugs.exam_payment}/:id`}><ExamExamPayment /></Route>

                  <Route exact path={slugs.classroom}><ClassRoom /></Route>
                  <Route exact path={`${slugs.start_class_exam}/:id`}><StartClassExam /></Route>

                  <Route path={`${slugs.start_exam}/:slug`}><StartExam /></Route>
                  <Route path={`${slugs.start_demo_exam}/:slug`}><StartDemoExam /></Route>
                  <Route path={`${slugs.start_daily_exam}/:slug`}><StartDailyExam /></Route>
                  <Route path={`${slugs.start_chapter_wise_exam}/:slug`}><StartChapterExam /></Route>

                  <Route path={slugs.exam_result}><ExamResult /></Route>
                  <Route path={slugs.daily_exam_result}><DailyExamResult /></Route>
                  <Route path={slugs.buy_exam_result}><MyExamResult /></Route>

                  <Route path={slugs.exam_result_list}><ExamAllResult /></Route>
                  <Route path={slugs.daily_exam_result_list}><DailyExamAllResult /></Route>
                  <Route path={slugs.buy_exam_result_list}><MyExamAllResult /></Route>
                  <Route path={slugs.classroom_exam_result_list}><ClassExamAllResult /></Route>
                  <Route path={slugs.class_result}><ClassExamResult /></Route>

                  <Route path={slugs.chapter_exam_result}><ChapterExamResult /></Route>
                  <Route path={slugs.chapter_wise_exam_result_list}><ChapterExamAllResult /></Route>

                  <Route path="*"><NotFound /></Route>
                </Switch>
              }
            </div>
          </div>
        </div>
      }

    </div >
  )
}
