import React from "react";
import FormComponent from "./Components/Form";
import Database from './../../../Constant/database.json'
import { slugs } from "../../../Constant/slugs";
import ExamData from './Components/examData.json'

export default function NewExam() {
  const FetchData = {
    "id": "",
    "title": {
      "rendered": ""
    },
    "meta": {
      "exam_question_block_text": ExamData.meta.exam_question_block_text,
      "exam_time": "",
      "exam_details": "",
      "available_unavailable": "Unavailable",
      "featured_image": "",
      "exam_type": "UBT"
    }
  };
  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>New Exam</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / <a href={slugs.all_exam}>All Exam</a>
        </div>
      </div>
      <FormComponent
        FetchData={FetchData}
        db_slug={slugs.db_slug_paid_exam}
        webSlug={slugs.exam_details}
      />
    </div>
  )
}