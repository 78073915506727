import React from 'react'
import { slugs } from '../../../Constant/slugs';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CustomButton } from '../../CustomButtom';
import ImageUpload from '../../ImageUpload';
import { NodePOSTApi, NodePUTApi, sideBarImage } from '../../../Constant/api';

export default function ExamBlock(props) {
  const { item, examSlug, AllExamList1, setAllExamList1, fetchExam } = props;
  const Login = useSelector((state) => state.LoginState);
  const [BuyModal, setBuyModal] = React.useState(false)
  const [BuyNow, setBuyNow] = React.useState(false)
  const [ExamPrice, setExamPrice] = React.useState("")
  const [PhoneNumber, setPhoneNumber] = React.useState(Login.userPhoneNumber)
  const [Email, setEmail] = React.useState(Login.userEmail)
  const [VerificationMethod, setVerificationMethod] = React.useState("Esewa")
  const [VerificationID, setVerificationID] = React.useState("")

  const [pid, setPid] = React.useState("")

  const orderDataFilter = AllExamList1.order_data.filter(a => a.meta.course_id_order == item.id)
  const myform = React.useRef(null)
  function generateRandomNumber() {
    var minm = 1000;
    var maxm = 9999;
    return Math.floor(Math.random() * (maxm - minm + 1)) + minm;
  }
  const BuyExamHandle = () => {
    var data;
    data = {
      "title": `${Login.userID}-${item.id}`,
      "status": "publish",
      "author": `${Login.userID}`,
      meta: {
        "exam_name_order": item.title.rendered,
        "exam_id_order": `${item.id}`,
        "student_id_order": `${Login.userID}`,
        "student_email_order": Email,
        "student_phone_order": `${PhoneNumber}`,
        "student_username_order": `${Login.userName}`,
        "paid_amount": ExamPrice,
        "verification_status": "Unverified",
        "verification_method": VerificationMethod,
        "verification_date": "",
        "verification_id": `${VerificationID}`
      }
    }
    NodePOSTApi({ orderData: JSON.stringify(data) }, "buy-exam")
      .then(res => {
        console.log(res.data)
        if (VerificationMethod == "Manual") {
          fetchExam(setAllExamList1, {})
          setBuyModal(false)
        }
        else {
          const RandNum = generateRandomNumber()
          setPid(`${res.data.id}-${RandNum}`)
          setTimeout(() => {
            localStorage.setItem("pid", `${res.data.id}-${RandNum}`)
            localStorage.setItem("AmountPaid", `${ExamPrice}`)
            myform.current.submit()
          }, 100)
        }
      }
      )
  }
  const PayNowHandle = () => {
    var data;
    data = {
      "title": `${Login.userID}-${item.id}`,
      "status": "publish",
      "author": `${Login.userID}`,
      meta: {
        "exam_name_order": item.title.rendered,
        "exam_id_order": `${item.id}`,
        "student_id_order": `${Login.userID}`,
        "student_email_order": Email,
        "student_phone_order": `${PhoneNumber}`,
        "student_username_order": `${Login.userName}`,
        "paid_amount": ExamPrice,
        "verification_status": "Unverified",
        "verification_method": VerificationMethod,
        "verification_date": "",
        "verification_id": `${VerificationID}`
      }
    }
    NodePUTApi({ orderData: JSON.stringify(data), id: orderDataFilter[0].id }, "buy-exam")
      .then(res => {
        console.log(res.data)
        if (VerificationMethod == "Manual") {
          fetchExam(setAllExamList1, {})
          setBuyModal(false)
        }
        else {
          const RandNum = generateRandomNumber()
          setPid(`${res.data.id}-${RandNum}`)
          setTimeout(() => {
            localStorage.setItem("pid", `${res.data.id}-${RandNum}`)
            localStorage.setItem("AmountPaid", `${ExamPrice}`)
            myform.current.submit()
          }, 100)
        }
      }
      )
  }
  return (
    <div>
      <div style={{
        padding: "5px 0", margin: "5px 0px", border: "1.5px solid #bbb",
        borderRadius: "5px",
      }} className="row">
        <div style={{ width: "100%", }}>
          <div className="row" >
            <div className="col-3" style={{ textAlign: "center" }}>
              <img
                src={item.meta.featured_image == "" ? sideBarImage : item.meta.featured_image}
                style={{ width: "100%" }}
              />
            </div>
            <table className="col-9">
              <tbody>
                <tr>
                  <td className="col-6">Name</td>
                  <td className="col-6"> {item.title.rendered}</td>
                </tr>
                <tr>
                  <td className="col-6">Exam Time</td>
                  <td className="col-6"> {item.meta.exam_time} min</td>
                </tr>
                {orderDataFilter.length !== 0 ?
                  <>
                    <tr>
                      <td className="col-6">Exam Price</td>
                      <td className="col-6"><span style={{ textDecorationLine: "line-through" }}>Rs {item.meta.exam_price * 2}</span> Rs {item.meta.exam_price}</td>
                    </tr>
                  </>
                  :
                  <>
                    {!(item.meta.exam_price == "0" || item.meta.exam_price == "") &&
                      <>
                        <tr>
                          <td className="col-6">Exam Price</td>
                          <td className="col-6"><span style={{ textDecorationLine: "line-through" }}>Rs {item.meta.exam_price * 2}</span> Rs{item.meta.exam_price} </td>
                        </tr>
                      </>
                    }
                  </>
                }
              </tbody>
            </table>
          </div>
        </div>
        <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
          {item.meta.available_unavailable == "Available" ?
            <div>
              {orderDataFilter.length !== 0 ?
                <div>

                  <div>
                    {orderDataFilter.filter(a => a.meta.verification_status == "Verified").length !== 0 ?
                      <Link className=" Link" to={`${examSlug}/${item.slug}`}>
                        <div className="sign-in-button-12">
                          Start Exam
                        </div>
                      </Link>
                      :
                      <div className="row">

                        <Link className=" Link" to={`${slugs.question_bank_exam_payment}/${item.slug}`}>
                          <div className="sign-in-button-12"
                            onClick={() => {
                            }}
                          >
                            Pay Now
                          </div>
                        </Link>

                      </div>
                    }
                  </div>
                </div>
                :
                <div className="row">
                  {item.meta.exam_price == "0" || item.meta.exam_price == "" ?
                    <div>
                      <Link className=" Link" to={`${examSlug}/${item.slug}`}>
                        <div className="sign-in-button-12">
                          Start Exam
                        </div>
                      </Link>
                    </div>
                    :
                    <Link className=" Link" to={`${slugs.question_bank_exam_payment}/${item.slug}`}>
                      <div className="sign-in-button-12"
                      >
                        Buy Exam
                      </div>
                    </Link>
                  }
                </div>
              }
            </div>
            :
            <div className="sign-in-button-12 Link" style={{ color: "#000", backgroundColor: "#fff" }}>
              Unavailable
            </div>
          }
        </div>
      </div >
      {BuyModal &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setBuyModal(false)
                  }}
                >X</div>
                <table>
                  <tbody>
                    <tr>
                      <td>Exam Name :</td>
                      <td>{item.title.rendered}</td>
                    </tr>
                    <tr>
                      <td>Exam ID : </td>
                      <td>{item.id}</td>
                    </tr>
                    <tr>
                      <td>Student Name : </td>
                      <td>{Login.userName}</td>
                    </tr>
                    <tr>
                      <td>Student ID : </td>
                      <td>{Login.userID}</td>
                    </tr>
                    <tr>
                      <td>Student Email : </td>
                      <td>{Login.userEmail}</td>
                    </tr>
                    <tr>
                      <td>Student Phone Number : </td>
                      <td>{Login.userPhoneNumber}</td>
                    </tr>
                    <tr>
                      <td>Exam Cost : </td>
                      <td>Rs. {ExamPrice}</td>
                    </tr>
                    <tr>
                      <td>Discount : </td>
                      <td>Rs. {ExamPrice}</td>
                    </tr>
                    <tr>
                      <td>Payment Method : </td>
                      <td>
                        <CustomButton setData={setVerificationMethod} Data={VerificationMethod} DataValue={"Esewa"} />
                        <CustomButton setData={setVerificationMethod} Data={VerificationMethod} DataValue={"Khalti"} />
                        <CustomButton setData={setVerificationMethod} Data={VerificationMethod} DataValue={"Manual"} />
                      </td>
                    </tr>
                    {VerificationMethod == "Manual" &&
                      <tr>
                        <td>Verification Image : </td>
                        <td>
                          <div className="col-12">
                            <ImageUpload
                              ImageUrl={VerificationID}
                              setImageUrl={setVerificationID}
                            />
                            {VerificationID == "" &&
                              "Attach slip"}
                          </div>
                        </td>
                      </tr>
                    }
                    <tr>
                      <td colSpan={2}>
                        <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                          {VerificationID == "" &&
                            <div className="sign-in-button-12 col-4"
                              onClick={() => {
                                if (BuyNow)
                                  BuyExamHandle()
                                else
                                  PayNowHandle()
                              }}
                            >
                              Continue
                              {VerificationMethod == "Esewa" && " with Esewa"}
                              {VerificationMethod == "Khalti" && " with Khalti"}
                            </div>
                          }
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      }
      <form ref={myform} action="https://uat.esewa.com.np/epay/main" method="POST">
        <input value={ExamPrice} name="tAmt" type="hidden" />
        <input value={ExamPrice} name="amt" type="hidden" />
        <input value="0" name="txAmt" type="hidden" />
        <input value="0" name="psc" type="hidden" />
        <input value="0" name="pdc" type="hidden" />
        <input value="EPAYTEST" name="scd" type="hidden" />
        <input value={pid} name="pid" type="hidden" />
        <input value={`http://localhost:3006/all-exam-list?`} type="hidden" name="su" />
        <input value={`http://localhost:3006/all-exams-list`} type="hidden" name="fu" />
      </form>
    </div >

  )
}
