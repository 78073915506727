import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { AdsGETApiAuth, AdsPUTApi } from "../../../Constant/api";
import { slugs } from "../../../Constant/slugs";


export default function AllQuestionBankExamOrders() {
  const [Projects1, setProjects1] = React.useState([])
  const [CustomersList, setCustomersList] = React.useState([])
  const [StudentsList, setStudentsList] = React.useState([])
  const [ExamList, setExamList] = React.useState([])
  const [Reload, setReload] = React.useState(false)
  const [AllTotal, setAllTotal] = React.useState(0)
  const [serachStudent, setSearchStudent] = React.useState("")
  const [serachExam, setSearchExam] = React.useState("")
  const [serachExamID, setSearchExamID] = React.useState("")
  const [SubscriberTotal, setSubscriberTotal] = React.useState(0)
  const [StudentTotal, setStudentTotal] = React.useState(0)
  const [AdminTotal, setAdminTotal] = React.useState(0)
  const [ExamTotal, setExamTotal] = React.useState(0)
  const [choose, setChoose] = React.useState(0)

  const GetAllProjects = (params, setData, slug, setTotal) => {
    setReload(true)
    setProjects1([])
    AdsGETApiAuth(params, slug)
      .then((res) => {
        setData(res.data)
        setTotal(res.headers["x-wp-total"])
        if (slug == slugs.db_slug_question_bank_exam_orders)
          setCustomersList(res.data)
      })
      .catch(err => {
      }).finally(() => {
        setReload(false)
      })
  }

  useEffect(() => {
    GetAllProjects({
      per_page: 49000,
      _fields: "id,title,slug,meta.exam_id_order,meta.exam_name_order,meta.student_id_order,meta.student_phone_order,meta.verification_status,meta.paid_amount,meta.verification_method,meta.verification_id"
    }, setProjects1, slugs.db_slug_question_bank_exam_orders, setAllTotal)
    GetAllProjects({
      per_page: 500,
      _fields: "id,title,slug,meta.available_unavailable,meta.exam_type"
    }, setExamList, slugs.db_slug_question_bank, setExamTotal)
    GetAllProjects({
      per_page: 4900,
      _fields: "id,title,slug,meta.user_phone_number,meta.batch_name,meta.user_id"
    }, setStudentsList, slugs.db_slug_user_data, setStudentTotal)
    // GetTotal("student", setStudentTotal, slugs.db_slug_user_data)
  }, [])

  const columnsExam = [
    {
      id: 1,
      name: "id",
      selector: (row) => row.id,
      sortable: true,
      reorder: true,
      width: "100px"
    },
    {
      id: 31,
      name: "Title",
      selector: (row) => row.title.rendered,
      sortable: true,
      reorder: true,
    },
    {
      id: 33,
      name: "Availability",
      selector: (row) => row.meta.available_unavailable,
      sortable: true,
      reorder: true,
      width: "150px"
    },
    {
      id: 34,
      name: "Type",
      selector: (row) => row.meta.exam_type,
      sortable: true,
      reorder: true
    },
    {
      id: 34,
      name: "Total Exams",
      selector: (row) => CustomersList.filter(a => a.meta.exam_id_order == row.id).length,
      sortable: true,
      reorder: true
    },
    {
      id: 3,
      name: "Choose",
      right: true,
      cell: (row) => <div style={{ cursor: "pointer" }}
        onClick={() => {
          setProjects1(CustomersList.filter(a => a.meta.exam_id_order == row.id))
          setChoose(0)
        }}
      >
        Choose
      </div>,

    }
  ];
  const columnsStudent = [
    {
      id: 1,
      name: "id",
      selector: (row) => row.meta.user_id,
      sortable: true,
      reorder: true,
    },
    {
      id: 31,
      name: "Title",
      selector: (row) => row.title.rendered,
      sortable: true,
      reorder: true
    },
    {
      id: 312,
      name: "Phone",
      selector: (row) => row.meta.user_phone_number,
      sortable: true,
      reorder: true
    },
    {
      id: 32,
      name: "Batch",
      selector: (row) => row.meta.batch_name,
      sortable: true,
      reorder: true
    },
    {
      id: 3421,
      name: "Total Exams",
      selector: (row) => CustomersList.filter(a => a.meta.student_id_order == row.meta.user_id).length,
      sortable: true,
      reorder: true
    },
    {
      id: 3123,
      name: "Choose",
      right: true,
      cell: (row) => <div style={{ cursor: "pointer" }}
        onClick={() => {
          setProjects1(CustomersList.filter(a => a.meta.student_id_order == row.meta.user_id))
          setChoose(0)
        }}
      >
        Choose
      </div>,

    }
  ];
  const columns = [
    {
      id: 1,
      name: "id",
      selector: (row) => row.id,
      sortable: true,
      reorder: true,
      width: "80px",
      compact: true, padding: 0,
    },
    {
      id: 31,
      name: "Exam",
      selector: (row) => row.meta.exam_name_order,
      sortable: true,
      reorder: true,
      compact: true, padding: 0,
    },
    {
      id: 33,
      name: "Exam ID",
      selector: (row) => row.meta.exam_id_order,
      sortable: true,
      reorder: true,
      compact: true,
      padding: 0,
    },
    {
      id: 34,
      name: "Student ID",
      selector: (row) => row.meta.student_id_order,
      sortable: true,
      center: true,
      reorder: true,
      compact: true,
      padding: 0,
    },
    {
      id: 34,
      name: "Phone No",
      selector: (row) => row.meta.student_phone_order,
      sortable: true,
      compact: true, padding: 0,
      reorder: true
    },
    {
      id: 34,
      name: "Verification",
      selector: (row) => row.meta.verification_status,
      sortable: true,
      reorder: true,
      compact: true,
      padding: 0,
    },
    {
      id: 34,
      name: "Amount",
      selector: (row) => row.meta.paid_amount,
      sortable: true,
      reorder: true,
      center: true,
      compact: true,
      padding: 0,
    },
    {
      id: 32,
      name: "Delete",
      center: true,
      compact: true,
      padding: 0,
      cell: (row) => <div style={{ cursor: "pointer" }}
        onClick={() => {
          AdsPUTApi({
            status: "draft"
          }, slugs.db_slug_question_bank_exam_orders, row.id)
            .then((res) => {
              setSearchStudent("")
              setSearchExam("")
              setSearchExamID("")
              GetAllProjects({
                per_page: 4900,
                _fields: "id,title,slug,meta.student_id_results,meta.exam_id_results,meta.exam_total_results,meta.exam_date_results,meta.exam_name_results"
              }, setProjects1, slugs.db_slug_question_bank_exam_orders, setAllTotal)

            })
            .catch((err) => {
            })
        }}
      >Delete</div>,

    },
    {
      id: 32,
      name: "View",
      center: true,
      compact: true,
      padding: 0,
      cell: (row) => <Link to={`${slugs.question_bank_order_details}${row.slug}`}>View</Link>,

    }
  ];
  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>All Orders</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / All Orders
        </div>
      </div>
      <a href={slugs.new_order}>
        <div className="sign-in-button-4" style={{ width: "150px", margin: "10px" }}
          onClick={() => {

          }}
        >Add New Order</div>
      </a>
      <div className="row">
        <div className="col-md-7" style={{ paddingLeft: "40px" }}>
          <div>
            <div style={{ display: "inline", cursor: "pointer" }}>
              <div style={{ display: "inline-block", margin: "0px 5px" }}
                onClick={() => {
                  setChoose(1)
                }}
              >
                Exams ({ExamTotal}) |
              </div>
              <div style={{ display: "inline-block", margin: "0px 5px" }}
                onClick={() => {
                  setChoose(2)
                }}
              >
                Students ({StudentTotal}) |
              </div>
              <div style={{ display: "inline-block", margin: "0px 5px" }}
                onClick={() => {
                  setChoose(0)
                  setProjects1(CustomersList)
                }}
              >
                Clear |
              </div>
            </div>
          </div>
          <div>
            <div style={{ display: "inline", cursor: "pointer" }}>
              <div style={{ display: "inline-block", margin: "0px 5px" }}
                onClick={() => {
                  setChoose(0)
                  setProjects1(CustomersList)
                }}
              >
                All Orders ({AllTotal}) |
              </div>
              <div style={{ display: "inline-block", margin: "0px 5px" }}
                onClick={() => {
                  setChoose(0)
                  setProjects1(CustomersList.filter(a => a.meta.verification_status == "Verified"))
                }}
              >
                Verified ({CustomersList.filter(a => a.meta.verification_status == "Verified").length}) |
              </div>
              <div style={{ display: "inline-block", margin: "0px 5px" }}
                onClick={() => {
                  setChoose(0)
                  setProjects1(CustomersList.filter(a => a.meta.verification_status == "Unverified"))
                }}
              >
                Unverified ({CustomersList.filter(a => a.meta.verification_status == "Unverified").length}) |
              </div>
              <div style={{ display: "inline-block", margin: "0px 5px" }}
                onClick={() => {
                  setChoose(0)
                  setProjects1(CustomersList.filter(a => a.meta.verification_status == "Unverified" && a.meta.verification_method == "Manual"&&a.meta.verification_id.includes("https://")))
                }}
              >
                Manual ({CustomersList.filter(a => a.meta.verification_status == "Unverified" && a.meta.verification_method == "Manual"&&a.meta.verification_id.includes("https://")).length}) |
              </div>
            </div>
          </div>

        </div>
        <div className="col-md-5 row">
          <div className="col-4" />
          <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
            value={serachExam} placeholder="Exam Name"
            onChange={(event) => {
              setSearchExam(event.target.value)
              setProjects1(CustomersList.filter(a => a.meta.exam_name_results.includes(event.target.value)))
            }}
          />
          <input className="col-2" style={{ margin: "1px 2px", padding: "3px 2px" }}
            value={serachExamID} placeholder="Exam ID"
            onChange={(event) => {
              setSearchExamID(event.target.value)
              setProjects1(CustomersList.filter(a => a.meta.exam_id_results.includes(event.target.value)))
            }}
          />
          <input className="col-2" style={{ margin: "1px 2px", padding: "3px 2px" }}
            value={serachStudent} placeholder="Student Phone"
            onChange={(event) => {
              setSearchStudent(event.target.value)
              setProjects1(CustomersList.filter(a => a.meta.student_phone_order.includes(event.target.value)))
            }}
          />
        </div>
      </div>


      <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px" }}>
        {choose == 0 &&
          <DataTable
            columns={columns}
            data={Projects1}
            defaultSortFieldId={1}
            defaultSortAsc={false}
            striped
            // sortIcon={<SortIcon />}
            pagination
            selectableRows
          />
        }
        {choose == 1 &&
          <DataTable
            columns={columnsExam}
            data={ExamList}
            defaultSortFieldId={1}
            defaultSortAsc={false}
            striped
            // sortIcon={<SortIcon />}
            pagination
            selectableRows
          />
        }
        {choose == 2 &&
          <DataTable
            columns={columnsStudent}
            data={StudentsList}
            defaultSortFieldId={1}
            defaultSortAsc={false}
            striped
            // sortIcon={<SortIcon />}
            pagination
            selectableRows
          />
        }
      </div>
      {Reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}