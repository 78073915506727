import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsGETApi, AdsPOSTApi, AdsPUTApi, UserPlaceHolder } from "../../../Constant/api";
import { slugs } from "../../../Constant/slugs";
import { useSelector } from "react-redux";
import ThridScreenComponent from "./FirstScreen";

const AnswerOptionsSelect = ({
  AnswerArray,
  setData, Data,
  DataValue,
  option,
  ArrayIndex,
  index,
}) => {
  return (
    <div className="answer-option-style"
      style={{ display: 'flex', }}
    >
      <div style={{ marginRight: "5px", cursor: "pointer" }}
        onClick={() => {
          setData(option)
          const ArrayJSONvalue = AnswerArray[ArrayIndex]
          AnswerArray[ArrayIndex] = {
            question_number: ArrayJSONvalue.question_number,
            correct_answer: ArrayJSONvalue.correct_answer,
            answerChoosen: option,
            solved: 1
          }
        }}
      >
        {Data == option ?
          <div style={{
            border: "4px solid #000",
            borderRadius: "20px",
            width: "30px",
            height: "30px", display: "flex", justifyContent: "center", alignContent: "center",
            fontSize: "16px", fontWeight: "bold", color: "#fff", backgroundColor: "#000"
          }}>
            &nbsp;{index}&nbsp;
          </div>
          :
          <div style={{
            border: "4px solid black",
            borderRadius: "20px",
            width: "30px",
            height: "30px", display: "flex", justifyContent: "center", alignContent: "center",
            fontSize: "16px", fontWeight: "bold"
          }}>
            &nbsp;{index}&nbsp;
          </div>
        }
        {/* {Data == option ?
          <i className="fa fa-check-circle-o" style={{ fontSize: 25, color: "green" }}></i>
          : <i className="fa fa-circle-o" style={{ fontSize: 25 }}></i>
        } */}
      </div>

      <div className={Data == option ? "active-option2" : "passive-option2"}>
        <span className="answer-text" >
          {DataValue}
        </span>
      </div>
    </div>
  )
}


export default function ColorVision(props) {
  const location = useLocation()
  const history = useHistory()
  const [ID, setID] = React.useState(245394)
  const [Title, setTitle] = React.useState("")
  const LoginUser = useSelector((state) => state.LoginState);

  const [reload, setReload] = React.useState(false)
  const [ImageArray, setImageArray] = React.useState([])
  const [ImageArrayReload, setImageArrayReload] = React.useState("")
  const [QuestionsBlock, setQuestionsBlock] = React.useState(true);
  const [QuestionsData, setQuestionsData] = React.useState([]);
  const [QuestionsEditData, setQuestionsEditData] = React.useState([])

  const [AnswerArray, setAnswerArray] = React.useState([]);
  const [ArrayIndex, setArrayIndex] = React.useState(0);
  const [option1, setOption1] = React.useState("");
  const [option2, setOption2] = React.useState("");
  const [option3, setOption3] = React.useState("");
  const [AnswerSelected, setAnswerSelected] = React.useState("")
  const [choose, setChoose] = React.useState(0)
  const [changeQue, setChangeQue] = React.useState(true)
  const [TotalCorrect, setTotalCorrect] = React.useState(0)

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")
  const EditInfo = () => {
    setReload(true)
    const data = {
      "meta": {
        images_list: JSON.stringify(QuestionsData)
      }
    }
    AdsPUTApi(data, "color_vision", ID)
      .then((res) => {
        setReload(false)
        setAlertShow(true)
      }).catch(err => {
        setReload(false)
        setAlertShow(true)
        setAlertcomp(err.response.data.message)
      })
  }
  useEffect(() => {
    fetchSliders()
  }, [])
  const fetchSliders = () => {
    setImageArrayReload(false)
    AdsGETApi({ _fields: "meta,title,id" }, `color_vision/${ID}`)
      .then((res) => {
        if (res.data.meta.images_list.length !== 0) {
          var questions = Object.values(JSON.parse(res.data.meta.images_list)).sort(() => Math.random() - 0.5);

          setQuestionsData(questions)

          setQuestionsEditData(questions[0])
          var option = Math.floor(Math.random() * 2) + 1
          if (option == 1) {
            setOption1()
          }
          const ArrayValue = Object.values(JSON.parse(res.data.meta.images_list))
          var mediaPlayed = {};
          for (let i = 0; i < ArrayValue.length; i++) {
            const QuestionJSON = ArrayValue[i]
            mediaPlayed = {
              ...mediaPlayed,
              [`question-${i + 1}`]: "0",
              [`answer-${i + 1}-a`]: "0",
              [`answer-${i + 1}-b`]: "0",
              [`answer-${i + 1}-c`]: "0",
              [`answer-${i + 1}-d`]: "0",
            }
            if (AnswerArray.length < ArrayValue.length)
              AnswerArray.push({
                image: QuestionJSON.image,
                number: QuestionJSON.number,
                correct_answer: "",
                answerChoosen: "",
              })
          }

          setTitle(res.data.title.rendered)
          setImageArrayReload(true)
        }
      })
      .catch(() => {
      })
  }
  return (
    <div>
      <div className="form-view" style={{ width: "102vw" }}>
        <div className=" d-sm-block d-none" style={{ userSelect: "none" }}>
          <div style={{ border: "1px solid #aaa", padding: "0px 0 10px", position: "relative" }}>

            {choose == 0 &&

              <div style={{ display: 'flex', justifyContent: 'center', padding: "30px" }}>
                <div style={{ width: "100%" }}>
                  <div style={{ textAlign: 'center' }}>
                    <h1>{Title}</h1>
                    <br />
                    <img src={UserPlaceHolder}
                      height="30px"
                    />
                  </div>
                  <div>
                    <br />
                    <b>Name of Student: {LoginUser.userName}</b>
                    <br />
                    <b>Student Email: {LoginUser.userEmail}</b>
                    <br />
                  </div>
                  <div>
                    <b>Exam description</b>
                    <br />
                    <div dangerouslySetInnerHTML={{ __html: Title }} />
                    {/* {ExamData.content.rendered} */}
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-lg-4 col-12" />
                    <div className="col-lg-4 col-12 center">
                      <button className="sign-in-button-11 col-lg-8 col-7"
                        onClick={() => {
                          setChoose(1)
                        }}
                      >
                        Get Started
                      </button>
                      <button className="sign-in-button-4 col-lg-6 col-6"
                        onClick={() => {
                          history.goBack()
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            }

            {choose == 1 &&
              <div>
                {AnswerArray.filter(a => a.correct_answer == a.answerChoosen && a.answerChoosen !== "").length < 8 ?
                  <>
                    {ArrayIndex !== Object.keys(QuestionsData).length - 1 ?
                      <>
                        {changeQue &&
                          <ThridScreenComponent
                            oneQuestionData={QuestionsEditData}
                            AllQuestionData={QuestionsData}
                            setAllQuestionData={setQuestionsData}
                            AnswerArray={AnswerArray}
                            ArrayIndex={ArrayIndex}
                            setTotalCorrect={setTotalCorrect}
                          />
                        }
                        {/* {AnswerArray.filter(a => a.answerChoosen !== "").length} */}
                        {/* {AnswerArray.filter(a => a.correct_answer == a.answerChoosen && a.answerChoosen !== "").length} */}
                        <div className="row mx-5 m-3" style={{ backgroundColor: "#fff" }} >
                          <div className="col-md-4">
                          </div>

                          <div className="col-md-4 question-list" style={{ cursor: "pointer" }}
                            onClick={() => {
                              setQuestionsEditData(QuestionsData[Object.keys(QuestionsData)[ArrayIndex + 1]])
                              setArrayIndex(ArrayIndex + 1)
                              setChangeQue(false)
                              setTimeout(() => {
                                setChangeQue(true)
                              }, 10)
                            }}
                          >
                            <button className={"sign-in-button-11"}>
                              Next &nbsp;  <i className="fa fa-chevron-right" />
                            </button>
                          </div>
                        </div>
                      </>
                      :
                      <>
                        <div style={{ padding: "20px" }}>
                          <h2>Sorry! </h2>
                          <br />

                          <center>Name: {LoginUser.userName}</center>
                          <center>You have failed Color Vision Test</center>
                        </div>
                      </>
                    }
                  </>
                  :
                  <div style={{ padding: "20px" }}>
                    <h2>Congratulations! </h2>
                    <br />

                    <center>Name: {LoginUser.userName}</center>
                    <center>You have cleared Color Vision Test</center>
                  </div>
                }

              </div>
            }
          </div>
        </div>

      </div>
      <div className="d-sm-none d-block">
        <div style={{ display: 'flex', justifyContent: 'center', padding: "30px" }}>
          <div style={{ width: "100%" }}>
            <div style={{ textAlign: 'center' }}>
              <h1>{Title}</h1>
              <br />
              {LoginUser.userPhoto === undefined ?
                <img src={UserPlaceHolder}
                  height="30px"
                />
                :
                <img src={`${LoginUser.userPhoto}`} className={"height-width-150 border border-dark"} />
              }
            </div>
            <div>
              <br />
              <b>Name of Student: {LoginUser.userName}</b>
              <br />
              <b>Student Email: {LoginUser.userEmail}</b>
              <br />
            </div>
            <div>
              <b>Exam description</b>
              <br />
            </div>
            <div style={{ fontWeight: "bold" }}>
              The Exam will be best suitable for landscape view.<br />
              Please Rotate your screen
            </div>
            <br />
            <div className="row">
              <div className="col-lg-4 col-12" />
              <div className="col-lg-4 col-12 center">
                <button className="sign-in-button-4 col-lg-6 col-6"
                  onClick={() => {
                    history.goBack()
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div >
  )

}