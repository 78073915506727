export const slugs = {
  
  "classroom": "/classroom",
  "start_class_exam": "/start-class-exam",

  "new_teacher": "/new-teacher",
  "teacher_details": "/teachers/",
  "all_teachers": "/all-teachers",

  "new_book": "/new-book",
  "books_details": "/admin/books/",
  "all_books": "/all-books",
  "db_slug_book": "books",

  "new_chapter_wise_exam": "/new-chapter",
  "chapter_details": "/chapters/",
  "all_chapters": "/all-chapters",
  "db_slug_chapter_wise_exam": "buy_exam",

  "new_batch_order": "/new-batch-order",
  "batch_order_details": "/batch-order/",
  "all_batch_orders": "/all-batch-order",
  "db_slug_batch_orders": "batch_order",
  "exam_payment": "/exam-payment",
  "question_bank_exam_payment": "/question-bank-exam-payment",
  "course_payment": "/course-payment",

  "new_course_order": "/new-paid-course-order",
  "course_order_details": "/paid_course-order/",
  "all_course_orders": "/all-course-order",
  "db_slug_course_orders": "course_orders",

  "new_course": "/new-paid-course",
  "course_details": "/paid-course/",
  "all_course": "/all-course",
  "db_slug_course": "paid_courses",

  "new_order": "/new-paid-exam-order",
  "order_details": "/paid-exam-order/",
  "all_orders": "/all-paid-exam-order",
  "db_slug_paid_exam_orders": "exam_order",

  "new_question_bank_order": "/new-question-bank-exam-order",
  "question_bank_order_details": "/question-bank-exam-order/",
  "all_question_bank_orders": "/all-question-bank-exam-order",
  "db_slug_question_bank_exam_orders": "question_bank_order",

  "batch_payment": "/batch-payment",
  "batch_exam": "/batch-exam",

  "new_daily_exam": "/new-daily-exam",
  "daily_exam_details": "/daily-exams/",
  "all_daily_exam": "/all-daily-exam",
  "db_slug_daily_exam": "daily_exam",

  "new_demo_exam": "/new-demo-exam",
  "demo_exam_details": "/demo-exams/",
  "all_demo_exam": "/all-demo-exam",
  "db_slug_demo_exam": "demo_exam",

  "new_question_bank": "/new-question-bank",
  "question_bank_details": "/question-bank/",
  "all_question_bank": "/all-question-bank",
  "db_slug_question_bank": "question_bank",

  "new_exam": "/new-exam",
  "exam_details": "/exams/",
  "all_exam": "/all-exam",
  "db_slug_paid_exam": "buy_exam",
  "result_admin_view":"/result-admin-view",
  "chapter_result_admin_view":"/chapter-result-admin-view",
  "daily_result_admin_view":"/package-result-admin-view",

  "new_buy_exam": "/new-buy-exam",
  "buy_exam_details": "/buy-exams/",
  "all_buy_exam": "/all-buy-exam",
  "db_slug_buy_exam": "buy_exam",

  "buy_exam_result_admin_view":"/buy-exam-result-admin-view",

  "all_buy_results": "/all-buy-results",
  
  "db_slug_buy_results": "buy_results",


  "result_details": "/results/",
  "all_results": "/all-results",
  "all_chapter_results": "/all-chapter-results",
  "db_slug_paid_results": "buy_results",
  "classroom_result_details": "/classroom-result/",
  "all_classroom_results": "/all-classroom-results",
  "db_slug_classroom_results": "classroom_result",

  "daily_result_details": "/daily-results/",
  "all_daily_results": "/all-daily-result",
  "db_slug_daily_results": "daily_results",

  "chapter_wise_result_details": "/chapter-wise-results/",
  "all_chapter_wise_results": "/all-chapter-wise-result",
  "db_slug_chapter_wise_results": "chapter_wise_results",

  "new_student": "/new-student",
  "student_details": "/students/",
  "all_students": "/all-students",
  "db_slug_user_data": "user_data",

  "new_batch": "/new-batch",
  "batch_details": "/batches/",
  "all_batch": "/all-batches",
  "db_slug_batch": "student_batch",

  "new_exam_categories": "/new-question-categories",
  "exam_categories_details": "/question-categories/",
  "all_exam_categories": "/all-question-categories",
  "db_slug_exam_categories": "question_category",

  "exam": "/all-exams-list",
  "question_bank_exam": "/all-question-bank-list",
  "demo_exam": "/all-demo-exam-list",
  "chapter_wise_exam": "/all-chapters-list",
  "daily_exam": "/all-daily-exam-list",
  "course": "/all-course-list",

  "start_exam": "/start-exam",
  "start_question_bank": "/start-question-bank",
  "start_course": "/course-view",
  "start_demo_exam": "/start-demo-exam",
  "start_chapter_wise_exam": "/start-chapter-exam",
  "start_daily_exam": "/start-daily-exam",

  "all_live_exam_admin": "/all-live-exam-admin-list",
  "start_live_exam_admin": "/start-exam-admin-list",

  "exam_result_list": "/my-exam-results",
  "daily_exam_result_list": "/my-daily-results",
  "classroom_exam_result_list": "/my-class-results",
  "buy_exam_result_list": "/my-results",
  "chapter_wise_exam_result_list": "/my-chapterwise-results",

  "exam_result": "/exam-result",
  "class_result": "/class-result",
  "daily_exam_result": "/daily-exam-result",
  "buy_exam_result": "/my-exam-result",
  "chapter_exam_result": "/chapter-exam-result",
}