import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { AdsGETApiAuth, NodePOSTApi } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import store from '../../../redux/store';
import NotFound from '../../NotFound';
import FirstScreenExam from '../ExamDetails';
import CourseViewTabComponent from './CourseViewTabComponent';

export default function CourseViewComponent(props) {
  const { Video, ExamData } = props;
  const [Chapter, setChapters] = React.useState([])
  const [Videos, setVideos] = React.useState([])
  const [Audios, setAudios] = React.useState([])
  const [PDF, setPDF] = React.useState([])
  const [Content, setContent] = React.useState([])
  const [Active, setActive] = React.useState(0)
  const [PreviewPressed, setPreviewPressed] = React.useState(false)
  const [PreviewUrl, setPreviewUrl] = React.useState("")
  const [AudioUrl, setAudioUrl] = React.useState("")
  const [AudioShow, setAudioShow] = React.useState("")
  const [PreviewType, setPreviewType] = React.useState("")
  const [PreviewObj, setPreviewObj] = React.useState({})

  useEffect(() => {
    var Data = Object.values(JSON.parse(ExamData.meta.course_content))
    setChapters(Data)
    var videos = [];
    var audio = [];
    var pdf = [];
    var text = [];
    for (let i = 0; i < Data.length; i++) {
      var thisdata = Data[i]
      if (thisdata.chapter_type == "Video")
        if (thisdata.youtube_id == "")
          // videos.push({
          videos = {
            ...videos,
            [Object.keys(videos).length]: {
              chapter_number: thisdata.chapter_number,
              title: thisdata.titile,
              video: thisdata.video_link
            }
          }
        // })
        else
          // videos.push({
          videos = {
            ...videos,
            [Object.keys(videos).length]: {
              chapter_number: thisdata.chapter_number,
              title: thisdata.title,
              video: `https://www.youtube.com/embed/${thisdata.youtube_id}`
            }
          }
      // })
      else if (thisdata.chapter_type == "PDF")
        // pdf.push({
        pdf = {
          ...pdf,
          [Object.keys(pdf).length]: {
            chapter_number: thisdata.chapter_number,
            title: thisdata.title,
            pdf_url: thisdata.pdf_url
          }
        }
      // })
      else if (thisdata.chapter_type == "Text")
        // text.push({
        text = {
          ...text,
          [Object.keys(text).length]: {
            chapter_number: thisdata.chapter_number,
            title: thisdata.title,
            content: thisdata.content
          }
        }
      // })
      console.log(thisdata)
      var OtherPDF = Object.values(JSON.parse(thisdata.pdf_list))
      console.log(OtherPDF)
      for (let j = 0; j < OtherPDF.length; j++) {
        pdf = {
          ...pdf,
          [Object.keys(pdf).length]: {
            chapter_number: thisdata.chapter_number,
            title: thisdata.title,
            pdf_url: OtherPDF[j]
          }
        }
      }
      console.log(pdf)
      var OtherAudio = Object.values(JSON.parse(thisdata.audio_list))
      for (let k = 0; k < OtherAudio.length; k++) {
        // audio.push({
        audio = {
          ...audio,
          [Object.keys(audio).length]: {
            chapter_number: thisdata.chapter_number,
            title: thisdata.title,
            audio_url: OtherAudio[k]
          }
        }
        // })
      }
    }
    setAudios(Object.values(audio))
    setPDF(Object.values(pdf))
    setVideos(Object.values(videos))
    setContent(Object.values(text))
  }, [])

  return (
    <div>

      <div className="row center">
        <div className="col-sm-2 col-12">
          <div className="shadow" style={{ cursor: "pointer", backgroundColor: "#fff", margin: "5px", padding: "10px" }}>
            <center>
              <div onClick={() => { }}>
                <img style={{ width: "40px" }} src={"https://api.dreamkoreaubttest.com/wp-content/uploads/2023/11/google-classroom.png"} />
              </div>
              <div>
                Live Class
              </div>
            </center>
          </div>
        </div>
        <div className="col-sm-2 col-6">
          <div onClick={() => { setActive(0) }} className="shadow" style={{ cursor: "pointer", backgroundColor: "#fff", margin: "5px", padding: "10px" }}>
            <center>
              <div>
                <img style={{ width: "40px" }} src={"https://api.dreamkoreaubttest.com/wp-content/uploads/2023/11/file-1.png"} />
              </div>
              <div>
                Chapters
              </div>
            </center>
          </div>
        </div>
        <div className="col-sm-2 col-6">
          <div onClick={() => { setActive(1) }} className="shadow" style={{ cursor: "pointer", backgroundColor: "#fff", margin: "5px", padding: "10px" }}>
            <center>
              <div >
                <img style={{ width: "40px" }} src={"https://api.dreamkoreaubttest.com/wp-content/uploads/2023/11/online-education.png"} />
              </div>
              <div>
                Videos
              </div>
            </center>
          </div>
        </div>
        <div className="col-sm-2 col-6">
          <div onClick={() => { setActive(2) }} className="shadow" style={{ cursor: "pointer", backgroundColor: "#fff", margin: "5px", padding: "10px" }}>
            <center>
              <div >
                <img style={{ width: "40px" }} src={"https://api.dreamkoreaubttest.com/wp-content/uploads/2023/11/543227.png"} />
              </div>
              <div>
                Audios
              </div>
            </center>
          </div>
        </div>
        <div className="col-sm-2 col-6">
          <div onClick={() => { setActive(3) }} className="shadow" style={{ cursor: "pointer", backgroundColor: "#fff", margin: "5px", padding: "10px" }}>
            <center>
              <div >
                <img style={{ width: "40px" }} src={"https://api.dreamkoreaubttest.com/wp-content/uploads/2023/11/pdf.png"} />
              </div>
              <div>
                Files
              </div>
            </center>
          </div>
        </div>
      </div>
      <div style={{ paddingTop: 20 }}>
        {Active == 0 &&
          <section>
            <h2>Course Chapters</h2>
            <div className="row" style={{ marginLeft: "10px" }}>
              {Chapter.map((item, index) => (
                <div key={index} className="col-sm-4 col-12">
                  <div className="shadow" style={{ cursor: "pointer", backgroundColor: "#fff", margin: "10px 10px 0 0 ", padding: "10px" }}
                    onClick={() => {
                      setPreviewPressed(true)
                      setPreviewObj(item)
                      setPreviewType(item.chapter_type)
                      if (item.chapter_type == "Video") {
                        if (item.youtube_id == "")
                          setPreviewUrl(item.video_link)
                        else
                          setPreviewUrl(`https://www.youtube.com/embed/${item.youtube_id}`)
                      }
                      else if (item.chapter_type == "PDF")
                        setPreviewUrl(item.pdf_url)
                      else
                        setPreviewUrl(item.content)

                    }}
                  >
                    {/* {JSON.stringify(item)} */}

                    <h4>{item.chapter_number} {item.title}</h4>
                    <div style={{ marginLeft: "40px", fontSize: "12px" }}>
                      <span>[{item.time}]</span>
                      <span>[{item.chapter_type}]</span>
                      <span>{JSON.parse(item.pdf_list).length !== 0 ? "[PDF]" : ""}</span>
                      <span>{JSON.parse(item.pdf_list).length !== 0 ? "[Audio]" : ""}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>
        }
      </div>
      <div style={{ paddingTop: 20 }}>
        {Active == 1 &&
          <section>
            <h2>Videos</h2>
            <div className="row" style={{ marginLeft: "10px" }}>
              {Videos.map((item, index) => (
                <div key={index} className="col-sm-3 col-12">
                  <div className="shadow" style={{ cursor: "pointer", backgroundColor: "#fff", margin: "10px 10px 0 0 ", padding: "10px" }}
                    onClick={() => {
                      const ChapterFind = Chapter.filter(a => a.chapter_number == item.chapter_number)[0]
                      setPreviewPressed(true)
                      setPreviewObj(ChapterFind)
                      setPreviewType(ChapterFind.chapter_type)
                      if (ChapterFind.chapter_type == "Video") {
                        if (ChapterFind.youtube_id == "")
                          setPreviewUrl(ChapterFind.video_link)
                        else
                          setPreviewUrl(`https://www.youtube.com/embed/${ChapterFind.youtube_id}`)
                      }
                      else if (ChapterFind.chapter_type == "PDF")
                        setPreviewUrl(ChapterFind.pdf_url)
                      else
                        setPreviewUrl(ChapterFind.content)

                    }}
                  >
                    {/* {JSON.stringify(item)} */}

                    <h4>{item.chapter_number} {item.title}</h4>
                    <div style={{ fontSize: "12px" }}>

                      <div className="row">
                        <div className="center col-12">
                          <div>
                            <img style={{ width: "40px" }} src={"https://api.dreamkoreaubttest.com/wp-content/uploads/2023/11/online-education.png"} />
                          </div>
                          Open Video
                        </div>
                        {/* <div className="center col-6">
                          <div>
                            <img style={{ width: "40px" }} src={"https://api.dreamkoreaubttest.com/wp-content/uploads/2023/11/online-education.png"} />
                          </div>
                          Open Chapter
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>
        }
      </div>
      <div style={{ paddingTop: 20 }}>
        {Active == 2 &&
          <section>
            <h2>Audios</h2>

            <div className="row" style={{ marginLeft: "10px" }}>
              {Audios.map((item, index) => (
                <div key={index} className="col-sm-3 col-12">
                  <div className="shadow" style={{ cursor: "pointer", backgroundColor: "#fff", margin: "10px 10px 0 0 ", padding: "10px" }}
                    onClick={() => {
                      const ChapterFind = Chapter.filter(a => a.chapter_number == item.chapter_number)[0]
                      setPreviewPressed(true)
                      setPreviewObj(ChapterFind)
                      setPreviewType(ChapterFind.chapter_type)
                      if (ChapterFind.chapter_type == "Video") {
                        if (ChapterFind.youtube_id == "")
                          setPreviewUrl(ChapterFind.video_link)
                        else
                          setPreviewUrl(`https://www.youtube.com/embed/${ChapterFind.youtube_id}`)
                      }
                      else if (ChapterFind.chapter_type == "PDF")
                        setPreviewUrl(ChapterFind.pdf_url)
                      else
                        setPreviewUrl(ChapterFind.content)

                    }}
                  >
                    {/* {JSON.stringify(item)} */}

                    <h4>{item.chapter_number} {item.title}</h4>
                    <div style={{ fontSize: "12px", textAlign: "center" }}>
                      <audio controls autoplay>
                        <source src={`${item.audio_url}`} type="audio/mpeg" />
                        <source src={`${item.audio_url}`} type="audio/mp3" />
                        <source src={`${item.audio_url}`} type="audio/wav" />
                        <source src={`${item.audio_url}`} type="audio/ogg" />
                        Your browser does not support the audio element.
                      </audio>
                      <br />
                      {item.audio_url.slice(61)}
                    </div>

                  </div>
                </div>
              ))}
            </div>
          </section>
        }
      </div>

      <div style={{ paddingTop: 20 }}>
        {Active == 3 &&
          <section>
            <h2>PDF</h2>

            <div className="row" style={{ marginLeft: "10px" }}>
              {PDF.map((item, index) => (
                <div key={index} className="col-sm-3 col-12">
                  <div className="shadow" style={{ cursor: "pointer", backgroundColor: "#fff", margin: "10px 10px 0 0 ", padding: "10px" }}
                    onClick={() => {
                      const ChapterFind = Chapter.filter(a => a.chapter_number == item.chapter_number)[0]
                      setPreviewPressed(true)
                      setPreviewObj(ChapterFind)
                      setPreviewType(ChapterFind.chapter_type)
                      if (ChapterFind.chapter_type == "Video") {
                        if (ChapterFind.youtube_id == "")
                          setPreviewUrl(ChapterFind.video_link)
                        else
                          setPreviewUrl(`https://www.youtube.com/embed/${ChapterFind.youtube_id}`)
                      }
                      else if (ChapterFind.chapter_type == "PDF")
                        setPreviewUrl(ChapterFind.pdf_url)
                      else
                        setPreviewUrl(ChapterFind.content)

                    }}
                  >
                    {/* {JSON.stringify(item)} */}

                    <h4>{item.chapter_number} {item.title}</h4>
                    <div className="row">
                      <div className="center col-11">
                        <div>
                          <img style={{ width: "40px" }} src={"https://api.dreamkoreaubttest.com/wp-content/uploads/2023/11/pdf-1.png"} />
                        </div>
                        {item.pdf_url.slice(61)}
                      </div>

                      {/* <div className="center col-6">
                          <div>
                            <img style={{ width: "40px" }} src={"https://api.dreamkoreaubttest.com/wp-content/uploads/2023/11/online-education.png"} />
                          </div>
                          Open Chapter
                        </div> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>
        }
      </div>


      {PreviewPressed &&
        <div className="modal">
          <div className="row">
            <div className="col-1" />
            <div class="modal-dialog" role="document" className="col-10">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">{ExamData.title.rendered}</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    onClick={() => { setPreviewPressed(false) }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div style={{ minHeight: "78vh", overflowY: "scroll" }}>

                    {PreviewType == "PDF" &&
                      <iframe
                        style={{ width: "100%", height: "80vh" }}
                        src={PreviewUrl}></iframe>
                    }
                    {PreviewType == "Video" &&
                      <>
                        <iframe
                          style={{ width: "100%", height: "70vh" }}
                          src={PreviewUrl
                            // PreviewObj.youtube_id == "" ? PreviewObj.video_link :
                            //   `https://www.youtube.com/embed/${PreviewObj.youtube_id}`
                          }>
                        </iframe>
                      </>
                    }
                    {PreviewType == "Text" &&
                      <div className="shadow" style={{ height: "70vh", backgroundColor: "#fff", margin: "5px", padding: "10px", overflowY: "scroll" }}>
                        {PreviewUrl}
                      </div>
                    }

                    {(PreviewObj.video_link !== "" || PreviewObj.youtube_id !== "") && PreviewType == "PDF" &&
                      <div>
                        <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                          Video
                        </span>
                        <div className="col-sm-2 col-4"
                          onClick={() => {
                            setPreviewType("Video")
                            if (PreviewObj.youtube_id == "")
                              setPreviewUrl(PreviewObj.video_link)
                            else
                              setPreviewUrl(`https://www.youtube.com/embed/${PreviewObj.youtube_id}`)

                            window.scrollTo({ top: 0, left: 0 });
                          }}
                        >
                          <div className="shadow" style={{ cursor: "pointer", backgroundColor: "#fff", margin: "5px", padding: "10px" }}>
                            <center>
                              <div onClick={() => { }}>
                                <img style={{ width: "40px" }} src={"https://api.dreamkoreaubttest.com/wp-content/uploads/2023/11/online-education.png"} />
                              </div>
                            </center>
                          </div>
                        </div>
                      </div>
                    }
                    <div>
                      <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                        Audio Resources
                      </span>
                      {Object.values(JSON.parse(PreviewObj.audio_list)).length !== 0 &&
                        <div className="row">
                          {Object.values(JSON.parse(PreviewObj.audio_list)).map((item, index) => (
                            <div key={index} className="col-sm-2 col-4">
                              {/* {item} */}
                              <div className="shadow" style={{ cursor: "pointer", backgroundColor: "#fff", margin: "5px", padding: "10px" }}
                                onClick={() => {
                                  // setAudioUrl(item)
                                }}
                              >
                                <center>
                                  <div onClick={() => { }}>
                                    {/* <img style={{ width: "40px" }} src={"https://api.dreamkoreaubttest.com/wp-content/uploads/2023/11/543227.png"} />
                                  </div> */}
                                    <audio controls autoplay>
                                      <source src={`${item}`} type="audio/mpeg" />
                                      <source src={`${item}`} type="audio/mp3" />
                                      <source src={`${item}`} type="audio/wav" />
                                      <source src={`${item}`} type="audio/ogg" />
                                      Your browser does not support the audio element.
                                    </audio>
                                  </div>

                                  {item.slice(61).replace(".pdf", "")}
                                </center>
                              </div>
                            </div>
                          ))}
                        </div>
                      }
                    </div>
                    <div>
                      <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                        PDF Resources
                      </span>
                      {Object.values(JSON.parse(PreviewObj.pdf_list)).length !== 0 &&
                        <div className="row">
                          {Object.values(JSON.parse(PreviewObj.pdf_list)).map((item, index) => (
                            <div key={index} className="col-sm-2 col-4"
                              onClick={() => {
                                setPreviewType("PDF")
                                setPreviewUrl(item)
                                window.scrollTo({ top: 0, left: 0 });
                              }}
                            >
                              {/* {item} */}
                              <div className="shadow" style={{ cursor: "pointer", backgroundColor: "#fff", margin: "5px", padding: "10px" }}>
                                <center>
                                  <div onClick={() => { }}>
                                    <img style={{ width: "40px" }} src={"https://api.dreamkoreaubttest.com/wp-content/uploads/2023/11/pdf.png"} />
                                  </div>
                                  {item.slice(61).replace(".pdf", "")}
                                </center>
                              </div>
                            </div>
                          ))}
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      {/* <div className="row">
        <div className="col-9">
          {Video.chapter_type == "PDF" &&
            <iframe
              style={{ width: "100%", height: "80vh" }}
              src={Video.pdf_url}></iframe>
          }
          {Video.chapter_type == "Video" &&
            <>
              {Video.youtube_id == "" ?
                <iframe
                  style={{ width: "100%", height: "78vh" }}
                  src={Video.video_link}>
                </iframe>
                :
                <iframe
                  style={{ width: "100%", height: "70vh" }}
                  src={`https://www.youtube.com/embed/${Video.youtube_id}`}>
                </iframe>
              }
            </>
          }
        </div>
        <div className="col-3">
          <div>
            <div style={{ fontSize: "18px", fontWeight: "bold" }}>Chapter Content</div>
            <div style={{ fontSize: "16px", fontWeight: "bold" }}>Audios</div>
            {Object.values(JSON.parse(Video.audio_list)).map((item2, index) => (
              <section key={index}>
                <div style={{ padding: "2px" }} className="row"  >
                  <div className="col-8">
                    <div style={{ border: "1px solid #bbb" }}>
                      <div style={{ display: "inline", padding: "auto" }}>
                        <div style={{ display: "inline-block" }}>
                          &nbsp; {index + 1} &nbsp;
                        </div>
                        <div style={{ display: "inline-block", padding: "5px" }}>
                          <audio controls style={{ height: "20px" }}>
                            <source src={`${item2}`} type="audio/mpeg" />
                            <source src={`${item2}`} type="audio/mp3" />
                            <source src={`${item2}`} type="audio/wav" />
                            <source src={`${item2}`} type="audio/ogg" />
                            Your browser does not support the audio element.
                          </audio>
                        </div>
                      </div>
                    </div>
                  </div>
                </div >
              </section>
            ))}
            <div style={{ fontSize: "16px", fontWeight: "bold" }}>Related Files</div>
            {Object.values(JSON.parse(Video.pdf_list)).map((item2, index) => (
              <section key={index}>
                <div style={{ padding: "2px" }} className="row"  >
                  <div style={{}} className="col-8">
                    <i className="fa fa-file" />
                    &nbsp; {index + 1} &nbsp;
                    {item2.slice(61)}
                  </div>
                </div>
              </section>
            ))}
          </div>
        </div>
      </div> */}

    </div >
  )
}
