import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { slugs } from '../../../Constant/slugs';
import FirstScreenResult from './FirstScreen';

import './styles.css'
export default function FinalScreenExam(props) {
  const {
    ExamData,
    QuestionsData,
    AnswerArray,
    userDetails,
    ResultData
  } = props;
  const [totalAttempt, setTotalAttempt] = React.useState(0)
  const [correctAnswer, setCorrectAnswer] = React.useState(0)
  const location = useLocation()
  useEffect(() => {
    var attempt = 0;
    var correct = 0;
    for (let i = 0; i < AnswerArray.length; i++) {
      if (AnswerArray[i].solved == 1)
        attempt++;
      if (AnswerArray[i].correct_answer == AnswerArray[i].answerChoosen)
        correct++
      setTotalAttempt(attempt);
      setCorrectAnswer(correct)
    }
  }, [])
  return (
    <div>
      {/* {JSON.stringify(location)} */}
      {/* {JSON.stringify(location).includes("start-class-exam") ? */}
      {true ?
        <FirstScreenResult
          ExamData={ExamData}
          AnswerArray={AnswerArray}
          ResultData={ResultData}
          StudentData={[{
            meta: {
              user_name: "",
              user_email: "",
              user_phone_number: "",
            }
          }]}
          QuestionsDataValue={
            JSON.stringify(location).includes("demo")?
            JSON.parse(ExamData.meta.exam_question_text):
            JSON.parse(ExamData.meta.exam_question_block_text)
          }
          Rank={1}
        />
        :
        <div style={{ padding: "20px" }}>
          <h2>Congratulations! </h2>
          <br />
          <br />

          <center>Name: {userDetails.name}</center>
          <center>Exam: {ExamData.title.rendered}</center>
          <br />
          <br />

          <div className="row row-data">
            <div className="col-8">Total Questions</div>
            <div className="col-4">{Object.keys(QuestionsData).length}</div>
          </div>
          <div className="row row-data">
            <div className="col-8">Total Attempt</div>
            <div className="col-4">{totalAttempt}</div>
          </div>
          <div className="row row-data">
            <div className="col-8">Total Correct</div>
            <div className="col-4">{correctAnswer}</div>
          </div>
          <div className="row row-data">
            <div className="col-8">Total Marks Obtained</div>
            <div className="col-4">{correctAnswer * 5}</div>
          </div>
          <div className="row row-data">
            <div className="col-8">Percentage Obtained</div>
            <div className="col-4">{(correctAnswer / AnswerArray.length * 100).toFixed(2)}%</div>
          </div>
          <div style={{ fontSize: "12px" }}> (each question carries 5 marks)
          </div>
          {location.pathname.includes(slugs.start_exam) &&
            <div className="row center">
              <Link to={`${slugs.exam_result}/${ResultData.slug}`} className="col-lg-2 col-12 Link">
                <div className="col-12 sign-in-button-1">
                  View All Answers
                </div>
              </Link>
            </div>
          }
          {location.pathname.includes(slugs.start_daily_exam) &&
            <div className="row center">
              <Link to={`${slugs.daily_exam_result}/${ResultData.slug}`} className="col-lg-2 col-12 Link">
                <div className="col-12 sign-in-button-1">
                  View All Answers
                </div>
              </Link>
            </div>
          }
          <div className="row center">
            <Link to={slugs.exam} className="col-lg-2 col-12 Link">

              <div className="col-12 sign-in-button-1">
                All Exam
              </div>
            </Link>
          </div>
        </div>
      }
    </div>
  )
}