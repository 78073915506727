import React, { useEffect } from 'react';
import { SortOrder } from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { AdsGETApi, AdsGETApiAuth, AdsPOSTApi, ExamBlockPlaceHolder, NodePOSTApi } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import { logout } from '../../../redux/action';
import store from '../../../redux/store';
import ExamBlock from './ExamBlock';


const slug = {
  "ubt": "UBT",
  "cbt-chemical": "CBT CHEMICAL",
  "cbt-electronics-electricity": "CBT ELECTRONIC ELECTRCITY",
  "cbt-food-related": "CBT FOOD RELATED",
  "cbt-mechinery-molding": "CBT MACHINERY,MOLDING",
  "cbt-metal": "CBT METAL",
  "cbt-pulp-paper-wood": "CBT PULP PAPER WOOD",
  "cbt-rubber": "CBT RUBBER",
  "cbt-textile-sewing": "CBT TEXTILE SEWING"
}
export default function AllQuestionExamList() {
  const [AllExamList1, setAllExamList1] = React.useState({
    exam_data: []
  });
  const [AllExamList2, setAllExamList2] = React.useState([]);
  const [reload, setReload] = React.useState(false)
  const [pagesLength, setPagesLength] = React.useState("1");
  const [pages, setPages] = React.useState(1);
  const [AllExam, setAllExam] = React.useState([]);
  const [AllSelectedExam, setSelectedExam] = React.useState([]);
  const [AllCategories, setAllCategories] = React.useState([]);
  const [AllExam2, setAllExam2] = React.useState([]);
  const [NewExam, setNewExam] = React.useState([]);
  const [MyExam, setMyExam] = React.useState([]);
  const [UnverifiedExam, setUnVerifiedExam] = React.useState([]);
  const [CompletedExam, setCompletedExam] = React.useState([]);
  const [choose, setChoose] = React.useState(0);

  const dispatch = useDispatch()

  const fetchExam = (setAllExamList, perPage) => {
    setReload(true)
    const id = store.getState().LoginState.userID
    NodePOSTApi({
      userID: id
    }, "all-quesion-bank-listing")
      .then((res) => {
        console.log(res.data)
        // var exam_data = res.data.exam_data.filter(a=>a.meta.exam_type=="UBT").sort((a,b)=>a.title.rendered-b.title.rendered);
        var exam_data = res.data.exam_data
        setAllCategories(res.data.category_data)
        setAllExamList(res.data)
        setAllExam(exam_data)
        setAllExam2(exam_data)
        setReload(false)
        setPagesLength(res.headers["x-wp-totalpages"])

        var arrCompleted = [];
        var arrMy = [];
        var arrAll = [];
        var arrUnVerified = [];
        var arrNew = [];
        // if (res.data.order_data.length !== 0)
        for (let i = 0; i < exam_data.length; i++) {
          var item = exam_data[i];
          const orderDataFilter = res.data.order_data.filter(a => a.meta.course_id_order == item.id)
          if (orderDataFilter.length !== 0) {
            if (orderDataFilter[0].meta.verification_status == "Verified") {
              arrMy.push(item)
              setMyExam(arrMy)
            }
            else {
              arrUnVerified.push(item)
              arrNew.push(item)
              setNewExam(arrNew)
              setUnVerifiedExam(arrUnVerified)
            }
          }
          else {
            if (item.meta.exam_price == "0" || item.meta.exam_price == "") {
              arrMy.push(item)
              setMyExam(arrMy)
            }
            else {
              arrNew.push(item)
              setNewExam(arrNew)
            }
          }
        }
        // else {
        //   setMyExam([])
        //   setCompletedExam([])
        //   setAllExam(res.data.exam_data)
        // }
        console.log(arrNew)
        // console.log(res.data.length)
      })
      .catch(err => {
        // console.log(err)
        // console.log(err.response.data)
        // if (err.message.includes("Request failed with status code 500"))
        //   dispatch(logout())
      })
  }


  const location = useLocation()
  useEffect(() => {
    fetchExam(setAllExamList1, {})
    console.log(location.pathname.slice(slugs.question_bank_exam))
  }, [])



  return (
    <div>
      <div className="row">
        <div className="col-1"></div>
        <div className="col-10 form-view mt-3">
          <div style={{ margin: "10px" }}>
            <h2>Question Bank </h2>
            <div style={{ margin: "10px", cursor: "pointer" }}>
              <span onClick={() => { setChoose(0) }}
              > All Exam ({AllCategories.length}) | </span>
              <span onClick={() => {
                setSelectedExam(MyExam)
                setChoose(1)
              }}
              >My Exam ({MyExam.length}) | </span>
            </div>
            {choose == 0 &&
              <div className="row">
                {AllCategories.map((item, index) => (
                  <div key={index} className="col-md-3 col-12 center" style={{ padding: "0 5px",cursor:"pointer" }}
                    onClick={() => {
                      setSelectedExam(AllExam.filter(a => a.meta.exam_category_id == item.id))
                      setChoose(1)
                    }}
                  >
                    <div style={{
                      padding: "5px", margin: "5px 0px", border: "1.5px solid #bbb",
                      borderRadius: "5px",
                    }}>
                      {item.meta.featured_image !== "" ?
                        <img className="col-12" src={item.meta.featured_image}
                          style={{ objectFit: 'contain', height: "100px", borderRadius: "3px" }} />
                        :
                        <img className="col-12"
                          src={ExamBlockPlaceHolder}
                          style={{ objectFit: 'contain', height: "100px", borderRadius: "3px" }} />
                      }
                      <br />
                      <div>
                        {item.title.rendered}&nbsp;
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            }
            {choose == 1 &&
              <div className="row">
                {AllSelectedExam.map((item, index) => (
                  <div key={index} className=" col-md-6 col-12 center" style={{ padding: "0 5px" }}>
                    <ExamBlock
                      item={item} examSlug={slugs.start_question_bank} AllExamList1={AllExamList1}
                      fetchExam={fetchExam} setAllExamList1={setAllExamList1}
                    />
                  </div>
                ))}
              </div>
            }
          </div>

        </div>
      </div>

      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}