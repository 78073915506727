import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { AdsGETApiAuth, NodePOSTApi } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import store from '../../../redux/store';
import NotFound from '../../NotFound';
import FirstScreenExam from '../ExamDetails';
import CourseViewComponent from './CourseViewComponent';

export default function StartCourse(props) {
  const dispatch = useDispatch()
  const location = useLocation()
  const [ExamData, setAllExamList] = React.useState({});
  const [ExamFetch, setExamFetch] = React.useState(false);
  const [NotFoundData, setNotFound] = React.useState(false)
  const [reload, setReload] = React.useState(false)
  const [Video, setVideo] = React.useState({})
  const [choose, setChoose] = React.useState(0)
  const [AnswerArray, setAnswerArray] = React.useState([]);
  const [ResultData, setResultData] = React.useState({});
  const history = useHistory()

  const fetchExam = (slug) => {
    setExamFetch(false)
    setReload(true)
    setNotFound(false)
    const id = store.getState().LoginState.userID

    NodePOSTApi({ userID: id, examSlug: slug }, "start-course-details")
      .then(res => {
        if (res.data.redirect) {
          history.push(`${slugs.course_payment}/${slug}`)
        }
        else {
          setAllExamList(res.data.exam_data[0])
          setExamFetch(true)
          var contentArray = Object.values(JSON.parse(res.data.exam_data[0].meta.course_content))
          if (contentArray.length !== 0)
            setVideo(contentArray[0])
        }
        // console.log(res.data)
      })
      .finally(() => {
        setReload(false)
      })
      .catch(err => {
        console.log(err)
        console.log(err.response)
      })

  }
  const [finishStatus, setfinishStatus] = React.useState(false);

  useEffect(() => {
    fetchExam(location.pathname.slice(slugs.start_course.length + 1))
  }, [])

  return (
    <div style={{ backgroundColor: "#eee", minHeight: "100vh" }}>
      <div>
        <div className="row">
          <div className="form-view" style={{ width: "102vw" }}>
            {ExamFetch &&
              <>
                {NotFoundData ?
                  <NotFound /> :
                  <>
                    <CourseViewComponent
                      Video={Video}
                      ExamData={ExamData}
                    />
                  </>
                }
              </>
            }
          </div>
        </div>
      </div>
      {
        reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div >
  )
}

const AudioPlay = ({ item2, index }) => {
  const [Play, setPlay] = React.useState(false)
  const sound = new Audio(item2)
  return (
    <div>

    </div>
  )
}