import React, { useEffect } from 'react'
import { slugs } from '../../../Constant/slugs';
import { Link, Redirect, useLocation, redirect, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import { CustomButton } from '../../CustomButtom';
import ImageUpload from '../../ImageUpload';
import { AdsPUTApi, NodePOSTApi, NodePUTApi } from '../../../Constant/api';
import NotFound from '../../NotFound';
import axios from 'axios';

export default function QuestionBankExamExamPayment(props) {
  const location = useLocation()
  const Login = useSelector((state) => state.LoginState);
  const [reload, setReload] = React.useState(true)
  const [ExamPrice, setExamPrice] = React.useState("")
  const [PhoneNumber, setPhoneNumber] = React.useState(Login.userPhoneNumber)
  const [Email, setEmail] = React.useState(Login.userEmail)
  const [VerificationMethod, setVerificationMethod] = React.useState("Esewa")
  const [VerificationID, setVerificationID] = React.useState("")
  const [pid, setPid] = React.useState("")
  const [BuyNow, setBuyNow] = React.useState(true)
  const [NewPayment, setNewPayment] = React.useState(true)
  const [item, setItem] = React.useState({})
  const [Results, setResults] = React.useState([])
  const [NotFoundData, setNotFound] = React.useState(false)
  const [OrderID, setOrderID] = React.useState("")
  const [KhaltiPaymentURL, setKhaltiPaymentURL] = React.useState("")
  const [ResetFreeExam, setResetFreeExam] = React.useState(false)
  const [ResetFreeExamID, setResetFreeExamID] = React.useState("")
  const history = useHistory()
  const CustomButton = ({ setData, Data, DataValue, ImageUrl }) => {
    return (
      <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
        onClick={() => {
          setData(DataValue)
        }}
      >
        <div className={Data == DataValue ? "active-option" : "passive-option"}>
          {ImageUrl !== "" ?
            <img src={ImageUrl} style={{ width: "100px", height: "30px", objectFit: "cover" }} />
            :
            <span style={{ width: "100px", height: "30px", }}>
              {DataValue}
            </span>
          }
        </div>
      </button>
    )
  }
  const [OrderData, setOrderData] = React.useState([])
  useEffect(() => {
    fetchExam(location.pathname.slice(slugs.question_bank_exam_payment.length + 1))
    window.scrollTo(0, 0)
  }, [])

  const fetchExam = (examID) => {
    setReload(true)
    NodePOSTApi({
      userID: Login.userID,
      examID: examID
    }, "question-bank-payment-details")
      .then((res) => {
        console.log(res.data)

        setReload(false)
        if (res.data.error)
          setNotFound(true)
        else {
          console.log(1)
          setItem(res.data.exam_data[0])
          console.log(2)
          setOrderData(res.data.order_data)
          console.log(3)
          setResults(res.data.result_data)
          setExamPrice(res.data.exam_data[0].meta.exam_price)
          console.log(5)
        }
      })
      .catch(err => {
        setNotFound(true)
        console.log(err)
        console.log(err.response)
      })
  }
  const myform = React.useRef(null)
  function generateRandomNumber() {
    var minm = 1000;
    var maxm = 9999;
    return Math.floor(Math.random() * (maxm - minm + 1)) + minm;
  }
  const BuyExamHandle = () => {
    const NewReset = OrderData.filter(a => a.meta.verification_status == "Verified").length == 0 ? "" : "1"
    var data;
    data = {
      "title": `${Login.userID}-${item.id}`,
      "status": "publish",
      // "author": `${Login.userID}`,
      meta: {
        "course_name_order": item.title.rendered,
        "course_id_order": `${item.id}`,
        "student_id_order": `${Login.userID}`,
        "student_email_order": Email,
        "student_phone_order": `${PhoneNumber}`,
        "student_username_order": `${Login.userName}`,
        "paid_amount": "0",
        "amount_to_pay": `${ExamPrice}`,
        "verification_status": "Unverified",
        "verification_method": VerificationMethod,
        "verification_date": "",
        "verification_id": `${VerificationID}`,
        "new_reset": NewReset
      }
    }
    NodePOSTApi({ orderData: JSON.stringify(data) }, "buy-question-bank")
      .then(res => {
        console.log(res.data)
        setOrderID(res.data.id)
        if (VerificationMethod == "Manual") {
          window.location.reload()
        }
        else if (VerificationMethod == "Esewa") {
          const RandNum = generateRandomNumber()
          setPid(`${res.data.id}-${RandNum}`)
          setTimeout(() => {
            localStorage.setItem("pid", `${res.data.id}-${RandNum}`)
            localStorage.setItem("AmountPaid", `${ExamPrice}`)
            myform.current.submit()
          }, 100)
        }
        else if (VerificationMethod == "MyPay") {
          MyPayPayment(res.data.id)
        }
        else {
          KhaltiPayment(res.data.id)
        }
      }
      )
  }

  const PayNowHandle = (id, verify) => {
    var data;
    const NewReset = OrderData.filter(a => a.meta.verification_status == "Verified").length == 0 ? "" : "1"
    data = {
      meta: {
        "paid_amount": "0",
        "amount_to_pay": `${verify.amount_to_pay}`,
        "verification_status": "Unverified",
        "verification_method": `${verify.verification_method}`,
        "verification_date": "",
        "verification_id": `${verify.verification_id}`,
        "new_reset": NewReset
      }
    }
    NodePUTApi({ orderData: JSON.stringify(data), id: id }, "buy-exam")
      .then(res => {
        console.log(res.data)
        setOrderID(id)
        if (verify.verification_method == "Manual") {
          window.location.reload()
        }
        else if (verify.verification_method == "Esewa") {
          const RandNum = generateRandomNumber()
          setPid(`${res.data.id}-${RandNum}`)
          setTimeout(() => {
            localStorage.setItem("pid", `${res.data.id}-${RandNum}`)
            localStorage.setItem("AmountPaid", `${ExamPrice}`)
            myform.current.submit()
          }, 100)
        }
        else {
          console.log(180)
          KhaltiPayment(res.data.id)
        }
      }
      )
  }

  const KhaltiPayment = async (orderID) => {
    const RandNum = generateRandomNumber()
    setPid(`${orderID}-${RandNum}`)
    localStorage.setItem("pid", `${orderID}-${RandNum}`)
    localStorage.setItem("AmountPaid", `${ExamPrice}`)
    console.log(192)

    NodePOSTApi(
      {
        ExamPrice: `${ExamPrice}00`,
        orderID: orderID,
        RandNum: RandNum,
        item: item,
        Login: Login,
        return_url: `https://exam.dreamkoreansmartclass.com/question_bank_payment_khalti/${orderID}/${location.pathname.slice(slugs.exam_payment.length + 1)}`
        // return_url: `http://localhost:3000/exam_payment_khalti/${orderID}/${location.pathname.slice(slugs.exam_payment.length + 1)}`
      }, "khalti_payment"
    )
      .then(res => {
        console.log(res.data)
        if (!res.data.error) {
          window.location.replace(res.data.data.payment_url)
        }
      })
      .catch(err => {
        console.log(211)
      })
  }

  const MyPayPayment = async (orderID) => {
    const RandNum = generateRandomNumber()
    setPid(`${orderID}-${RandNum}`)
    localStorage.setItem("pid", `${orderID}-${RandNum}`)
    localStorage.setItem("AmountPaid", `${ExamPrice}`)

  }

  const ResetExamComp = () => {
    var resetExamID;
    if (Results.filter(a => a.status == "publish").length !== 0) {
      resetExamID = Results.filter(a => a.status == "publish")[0].id
      setReload(true)
      AdsPUTApi({
        status: "draft"
      }, "paid_results", resetExamID)
        .then(res => {
          window.location.reload()
        })
        .finally(() => {
          setReload(false)
        })
    }

  }

  return (
    <div className="row">
      <div>
        {!reload &&
          <>
            {NotFoundData ?
              <NotFound /> :
              <div>
                <div className="row">
                  <div className="col-sm-2 col-1"></div>
                  <div className="col-sm-8 col-10 form-view m-3">
                    <table>
                      <tbody>
                        <tr>
                          <td>Exam Name :</td>
                          <td>{item.title.rendered}</td>
                        </tr>
                        <tr>
                          <td>Exam ID : </td>
                          <td>{item.id}</td>
                        </tr>
                        <tr>
                          <td>Student Name : </td>
                          <td>{Login.userName}</td>
                        </tr>
                        <tr>
                          <td>Student ID : </td>
                          <td>{Login.userID}</td>
                        </tr>
                        <tr>
                          <td>Student Email : </td>
                          <td>{Login.userEmail}</td>
                        </tr>
                        <tr>
                          <td>Student Phone Number : </td>
                          <td>{Login.userPhoneNumber}</td>
                        </tr>
                        <tr>
                          <td>New Exam Price: </td>
                          <td>  Rs {ExamPrice} </td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-2 col-1"></div>
                  <div className="col-sm-8 col-10 form-view m-3">
                    Orders Details:
                    {OrderData.length !== 0 &&
                      <table>
                        <tbody>
                          <tr>
                            <td>Amount</td>
                            <td>Paid</td>
                            <td>Status</td>
                            <td>Method</td>
                            <td>Date</td>
                            <td>ID</td>
                            <td></td>
                          </tr>

                          {OrderData.map((item, index) => (
                            <tr key={index}>

                              <td>Rs. {item.meta.amount_to_pay}</td>
                              <td>Rs. {item.meta.paid_amount}</td>
                              <td>{item.meta.verification_status}</td>
                              <td>{item.meta.verification_method}</td>
                              <td>{item.meta.verification_date}</td>
                              <td>{item.meta.verification_id.includes("https") ?
                                <div onClick={() => {

                                }}>
                                  <a href={item.meta.verification_id} target="_blank">
                                    image
                                  </a>
                                </div>
                                :
                                item.meta.verification_id
                              }</td>
                              <td>
                                {item.meta.verification_method !== "Manual" &&
                                  <>
                                    {item.meta.verification_status == "Unverified" &&
                                      <>
                                        {item.meta.amount_to_pay == ExamPrice &&
                                          <div className="sign-in-button-12"
                                            onClick={() => {
                                              PayNowHandle(item.id, item.meta)
                                            }}
                                          >
                                            Pay Now
                                          </div>
                                        }
                                      </>
                                    }
                                  </>
                                }
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    }
                    <br />
                    {(ExamPrice == "" || ExamPrice == "0") ?
                      <div>
                        {Results.filter(a => a.status == "publish").length == 0 &&
                          <Link className=" Link" to={`${slugs.start_exam}/${item.slug}`}>
                            <div className="sign-in-button-12 col-4"
                              onClick={() => { }}
                            >
                              Start Exam
                            </div>
                          </Link>
                        }
                      </div>
                      :
                      <div>
                        {OrderData.filter(a => a.meta.verification_status == "Verified").length == 0 ?
                          <div className="sign-in-button-12 col-4"
                            onClick={() => { setNewPayment(true) }}
                          >
                            Pay to start exam
                          </div>
                          :
                          <div>
                            {Results.filter(a => a.status == "publish").length == 0 &&
                              <Link className=" Link" to={`${slugs.start_exam}/${item.slug}`}>
                                <div className="sign-in-button-12 col-4"
                                  onClick={() => { }}
                                >
                                  Start Exam
                                </div>
                              </Link>
                            }
                          </div>
                        }

                      </div>
                    }
                    {NewPayment &&
                      <table>
                        <tbody>
                          <tr>
                            <td>Exam Cost : </td>
                            <td>Rs. {ExamPrice}</td>
                          </tr>
                          <tr>
                            <td>Payment Method : </td>
                            <td>
                              <CustomButton setData={setVerificationMethod} Data={VerificationMethod} DataValue={"Esewa"}
                                ImageUrl="https://epsexam.com/wp-content/uploads/2022/05/esewa_og.webp"
                              />
                              <CustomButton setData={setVerificationMethod} Data={VerificationMethod} DataValue={"Khalti"}
                                ImageUrl="https://dao578ztqooau.cloudfront.net/static/img/logo1.png"
                              />
                              {/* <CustomButton setData={setVerificationMethod} Data={VerificationMethod} DataValue={"MyPay"}
                                ImageUrl="https://mypay.com.np/frontend/images/logo.png"
                              /> */}
                              <CustomButton setData={setVerificationMethod} Data={VerificationMethod} DataValue={"Manual"} ImageUrl="" />
                            </td>
                          </tr>
                          {VerificationMethod == "Manual" &&
                            <tr>
                              <td>Verification Image : </td>
                              <td>
                                <div className="col-12">
                                  <ImageUpload
                                    ImageUrl={VerificationID}
                                    setImageUrl={setVerificationID}
                                  />
                                  {VerificationID == "" &&
                                    "Attach slip"}
                                </div>
                              </td>
                            </tr>
                          }
                          <tr>
                            <td colSpan={2}>
                              <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                                {(VerificationID !== "" || VerificationMethod !== "Manual") &&
                                  <div className="sign-in-button-12 col-4"
                                    onClick={() => {
                                      BuyExamHandle()
                                    }}
                                  >
                                    Continue
                                    {VerificationMethod == "Esewa" && " with Esewa"}
                                    {VerificationMethod == "Khalti" && " with Khalti"}
                                    {VerificationMethod == "MyPay" && " with MyPay"}
                                  </div>
                                }
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    }
                  </div>
                </div>
              </div>
            }
          </>
        }
        {ResetFreeExam &&

          <section className="modal" >
            <div className="row">
              <div className="col-md-4 col-1"></div>
              <div className="col-md-4 col-10  shadow bg-white">
                <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                  <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                    onClick={() => {
                      setResetFreeExam(false)

                    }}
                  >X</div>
                  Do you really want to reset the exam?<br />
                  Resetting exam is irreversible.
                  <tr>
                    <td colSpan={6}>
                      <div className="row">
                        <div className="col-1" />
                        <div className="col-5 center " >
                          <div className="sign-in-button-4" style={{ width: "80%" }}
                            onClick={() => {
                              setResetFreeExam(false)

                            }}
                          >
                            Cancel
                          </div>
                        </div>
                        <div className="col-5 center">
                          <div className="sign-in-button-4" style={{ width: "80%" }}
                            onClick={() => {
                              ResetExamComp(ResetFreeExamID)
                              setResetFreeExam(false)
                            }}
                          >
                            Reset Now
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </div>
              </div>
            </div>
          </section>

        }
        <form ref={myform} action="https://esewa.com.np/epay/main" method="POST">
          {/* <form ref={myform} action="https://uat.esewa.com.np/epay/main" method="POST"> */}
          <input value={ExamPrice} name="tAmt" type="hidden" />
          <input value={ExamPrice} name="amt" type="hidden" />
          <input value="0" name="txAmt" type="hidden" />
          <input value="0" name="psc" type="hidden" />
          <input value="0" name="pdc" type="hidden" />
          <input value="NP-ES-DREAMKOREAN" name="scd" type="hidden" />
          {/* <input value="EPAYTEST" name="scd" type="hidden" /> */}
          <input value={pid} name="pid" type="hidden" />
          {/* <input value={`http://localhost:3000/exam_payment/${OrderID}?buy=${BuyNow}&exam=${location.pathname.slice(slugs.exam_payment.length + 1)}`} type="hidden" name="su" /> */}
          <input value={`https://exam.dreamkoreansmartclass.com/question_bank_payment/${OrderID}?buy=${BuyNow}&exam=${location.pathname.slice(slugs.exam_payment.length + 1)}`} type="hidden" name="su" />
          {/* <input value={`https://localhost:3000${location.pathname}`} type="hidden" name="fu" /> */}
          <input value={`https://exam.dreamkoreansmartclass${location.pathname}`} type="hidden" name="fu" />
        </form>

        {reload &&
          <div className="modal" style={{ padding: "50vw" }}>
            <div className="dot-pulse"></div>
          </div>
        }
      </div >
    </div >
  )
}
