import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { slugs } from "../../../Constant/slugs";
import { AdsGETApiAuth, AdsPUTApi } from "../../../Constant/api";
const columns = [
  {
    id: 1,
    name: "id",
    selector: (row) => row.id,
    width: "70px",
    sortable: true,
    reorder: true,
    compact: true, padding: 0,
  },
  {
    id: 31,
    name: "Title",
    selector: (row) => row.title.rendered,
    sortable: true,
    reorder: true,
    compact: true, padding: 0,
  },
  {
    id: 312,
    name: "Status",
    selector: (row) => row.meta.batch_status,
    width: "100px",
    sortable: true, compact: true, padding: 0, reorder: true
  },
  {
    id: 312,
    name: "Price",
    selector: (row) => row.meta.batch_price,
    sortable: true, compact: true, padding: 0, reorder: true,
    width: "100px"
  },
  {
    id: 312,
    name: "Batch by",
    selector: (row) => row.meta.batch_institute_name,
    sortable: true, compact: true, padding: 0, reorder: true,
    width: "100px"
  },
  {
    id: 312,
    name: "Alllow Sell",
    selector: (row) => row.meta.batch_allow_buy,
    sortable: true, compact: true, padding: 0, reorder: true,
    width: "100px"
  },
  {
    id: 3,
    name: "View",
    right: true,
    width: "100px",
    cell: (row) => <Link to={`${slugs.batch_details}${row.slug}`}>View</Link>,

  }
];

export default function AllBatch() {
  const [Projects1, setProjects1] = React.useState([])
  const [CustomersList, setCustomersList] = React.useState([])
  const [Reload, setReload] = React.useState(false)
  const GetAllProjects = (params) => {
    setReload(true)
    setProjects1([])
    AdsGETApiAuth(params, slugs.db_slug_batch)
      .then((res) => {
        setProjects1(res.data)
        setCustomersList(res.data)
      })
      .catch(err => {
      }).finally(() => {
        setReload(false)
      })
  }
  useEffect(() => {
    GetAllProjects({
      per_page: 100,

    })

  }, [])
  const [bulkDelete, setBulkDelete] = React.useState(false)
  const [Projects2, setProjects2] = React.useState([])
 

  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>All Batches</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / All Batches
        </div>
      </div>
      {Projects2.length == 0 ?
        <div className="col-3" />
        :
        <div className="col-3">
          <div style={{ display: "inline-block", margin: "0px 5px", cursor: "pointer" }}
            onClick={() => {
              setBulkDelete(true)
            }}
          >
            Bulk Delete
          </div>
        </div>
      }
      <a href={slugs.new_batch}>
        <div className="sign-in-button-4" style={{ width: "150px", margin: "10px" }}
          onClick={() => {

          }}
        >Add New Batch</div>
      </a>
      <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px" }}>
        <DataTable
          columns={columns}
          data={CustomersList}
          defaultSortFieldId={1}
          defaultSortAsc={false}
          striped
          // sortIcon={<SortIcon />}
          pagination
          selectableRows
          onSelectedRowsChange={(r) => {
            console.log("asdasd", r)
            setProjects2(r.selectedRows)
          }}
        />
      </div>
      {bulkDelete &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-4 col-1"></div>
            <div className="col-md-4 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setBulkDelete(false)
                  }}
                >X</div>
                Do you really want to delete all the selected batches ?<br />
                Deleting batch is irreversible.
                <tr>
                  <td colSpan={6}>
                    <div className="row">
                      <div className="col-1" />
                      <div className="col-5 center " >
                        <div className="sign-in-button-4" style={{ width: "80%" }}
                          onClick={() => {
                            setBulkDelete(false)
                          }}
                        >
                          Cancel
                        </div>
                      </div>
                      <div className="col-5 center">
                        <div className="sign-in-button-4" style={{ width: "80%" }}
                          onClick={() => {
                            setBulkDelete(false)
                            for (let i = 0; i < Projects2.length; i++) {
                              setReload(true)
                              AdsPUTApi({
                                status: "draft"
                              }, slugs.db_slug_batch, Projects2[i].id)
                                .then((res) => {
                                  if (i == Projects2.length - 1) {
                                    GetAllProjects({
                                      per_page: 100,
                                    })
                                  }
                                })
                                .catch((err) => {
                                  console.log(err)
                                })
                            }
                          }}
                        >
                          Delete All
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </div>
            </div>
          </div>
        </section>

      }
      {Reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}